import {
  GROUP_LIST_REQUEST,
  GROUP_LIST_SUCCESS,
  GROUP_LIST_FAIL,
  GROUP_DETAILS_REQUEST,
  GROUP_DETAILS_SUCCESS,
  GROUP_DETAILS_FAIL,
  GROUP_SAVE_REQUEST,
  GROUP_SAVE_SUCCESS,
  GROUP_SAVE_FAIL,
  GROUP_DELETE_SUCCESS,
  GROUP_DELETE_FAIL,
  GROUP_DELETE_REQUEST,
  GROUP_STUDENT_UPDATE_REQUEST,
  GROUP_STUDENT_UPDATE_SUCCESS,
  GROUP_STUDENT_UPDATE_FAIL,

  GROUP_STUDENT_SAVE_REQUEST,
  // GROUP_STUDENT_SAVE_SUCCESS,
  GROUP_STUDENT_SAVE_FAIL,
  GROUP_STUDENT_UPDATE_RESET,

  SAVE_GROUP_RESET,
  SAVE_GROUP_REQUEST,
  SAVE_GROUP_SUCCESS,
  SAVE_GROUP_FAIL

} from '../constants/groupConstants';

import {
  GROUP_ACTIONS_URL
} from '../constants/urlConstants';

import axios from 'axios';
import Axios from 'axios';

const urlBase = GROUP_ACTIONS_URL;

const listGroups = (
  category = '',
  searchKeyword = '',
  sortOrder = ''
) => async (dispatch, getState) => {

  // ::::::::::::::: BLOCK FINAL ::::::::::::::

  dispatch({ type: GROUP_LIST_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/groups?category=' +
    category +
    '&searchKeyword=' +
    searchKeyword +
    '&sortOrder=' +
    sortOrder
    , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: GROUP_LIST_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: GROUP_LIST_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: GROUP_LIST_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

};

const resetGroupStudenUpdate = () => (dispatch) => {
  dispatch({ type: GROUP_STUDENT_UPDATE_RESET });
}


const updateStudentGroup = ({ checked, checkedGroup }) => async (dispatch, getState) => {

  // Indicamos que estamos haciendo una actualización

  dispatch({ type: GROUP_STUDENT_UPDATE_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/groupsStudent'
    , {
      method: 'PUT',
      body: JSON.stringify({ checked, checkedGroup }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: GROUP_STUDENT_UPDATE_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: GROUP_STUDENT_UPDATE_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: GROUP_STUDENT_UPDATE_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}



const detailsGroup = (groupId) => async (dispatch) => {
  try {
    dispatch({ type: GROUP_DETAILS_REQUEST, payload: groupId });
    const { data } = await axios.get('/api/groups/' + groupId);
    dispatch({ type: GROUP_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GROUP_DETAILS_FAIL, payload: error.message });
  }
};



const saveGroupStudent = (student) => async (dispatch, getState) => {

  // AQUÍ NICIA TODO
  // Indicamos que estamos haciendo una actualización

  dispatch({ type: GROUP_STUDENT_SAVE_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/student'
    , {
      method: 'POST',
      body: JSON.stringify({
        name: student.name,
        nickname: student.nickname,
        email: student.email,
        parentName: student.parentName,
        parentEmail: student.parentEmail,
        grade: student.grade
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: GROUP_STUDENT_UPDATE_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: GROUP_STUDENT_UPDATE_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: GROUP_STUDENT_SAVE_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}


const resetGroupSave = () => (dispatch) => {
  dispatch({ type: SAVE_GROUP_RESET });
}

const saveGroup = (student) => async (dispatch, getState) => {

  try {
    dispatch({ type: SAVE_GROUP_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    if (!student.id) {

      await fetch(
        urlBase +
        'user/group'
        , {
          method: 'POST',
          body: JSON.stringify({
            name: student.name,
            description: student.description
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userInfo.token
          }
        })
        .then(function (response) {
          if (response.ok) {
            ok = true;
          } else if (response.status === 400) {
            err400 = true;
          } else {
            // unknown = true;
          }
          return response.json();
        })
        .then(function (data) {
          // console.log('data => ', data);
          msg = data;
        })
        .catch(function (err) {
          console.error(err);
          // unknown = true;
        });

    } else {

      await fetch(
        urlBase +
        'user/group'
        , {
          method: 'PUT',
          body: JSON.stringify(student),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userInfo.token
          }
        })
        .then(function (response) {
          if (response.ok) {
            ok = true;
          } else if (response.status === 400) {
            err400 = true;
          } else {
            // unknown = true;
          }
          return response.json();
        })
        .then(function (data) {
          // console.log('data = ', data);
          msg = data;
        })
        .catch(function (err) {
          console.error(err);
          // unknown = true;
        });

    }

    if (ok) {
      dispatch({ type: SAVE_GROUP_SUCCESS, payload: msg });
    } else if (err400) {
      dispatch({ type: SAVE_GROUP_FAIL, payload: msg.errors });
    } else {
      alert("Error desconocido, intenta más tarde");
      dispatch({ type: SAVE_GROUP_FAIL, payload: [{ param: 'default', msg: "Ocurrió un error, intenta más tarde" }] });
    }

  } catch (error) {
    dispatch({ type: SAVE_GROUP_FAIL, payload: [{ msg: error.message }] });
  }

};

const deleteGroup = (student) => async (dispatch, getState) => {

  try {
    dispatch({ type: SAVE_GROUP_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    if (student.id) {

      await fetch(
        urlBase +
        'user/group'
        , {
          method: 'DELETE',
          body: JSON.stringify({
            id: student.id,
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userInfo.token
          }
        })
        .then(function (response) {
          if (response.ok) {
            ok = true;
          } else if (response.status === 400) {
            err400 = true;
          } else {
            // unknown = true;
          }
          return response.json();
        })
        .then(function (data) {
          // console.log('data => ', data);
          msg = data;
        })
        .catch(function (err) {
          console.error(err);
          // unknown = true;
        });

    }

    if (ok) {
      dispatch({ type: SAVE_GROUP_SUCCESS, payload: msg });
    } else if (err400) {
      alert("No se puede borrar: " + msg.errors[0].msg);
      dispatch({ type: SAVE_GROUP_FAIL, payload: msg.errors });
    } else {
      alert("Error desconocido, intenta más tarde");
      dispatch({ type: SAVE_GROUP_FAIL, payload: [{ param: 'default', msg: "Ocurrió un error, intenta más tarde" }] });
    }

  } catch (error) {
    dispatch({ type: SAVE_GROUP_FAIL, payload: [{ msg: error.message }] });
  }

};

export {
  listGroups,
  detailsGroup,
  saveGroup,
  updateStudentGroup,
  saveGroupStudent,
  resetGroupStudenUpdate,
  resetGroupSave,
  deleteGroup
};
