import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  listEnableUsers,
  updateEnableUsers,
  showUserInfo
} from '../actions/enableUsersActions';
import { makeStyles } from '@material-ui/core/styles';
import EnableUserDetailComponent from '../components/EnableUserDetailComponent';

// MATERIAL UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import CheckIcon from '@material-ui/icons/Check';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

function EnableUsersScreen(props) {

  const enableUsersList = useSelector(state => state.enableUsersList);
  const { loading: loadingUsers, users, errorListUsers } = enableUsersList;


  const enableUserUpdate = useSelector(state => state.enableUserUpdate);
  const { success: successUpdate } = enableUserUpdate;


  const dispatch = useDispatch();
  const [checked, setChecked] = useState([0]);
  const [disabledSelect, setDisabledSelect] = useState(true);


  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      backgroundColor: '#f5f5f5',
      minHeight: 'calc(100vh - 64px)',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'right',
      color: theme.palette.text.secondary,
    },
    paper2: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    list: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 300,

    },
    margin: {
      margin: theme.spacing(2),
    },
    rightAlng: {
      textAlign: 'left',
    }
  }));

  const classes = useStyles();

  useEffect(() => {
    dispatch(listEnableUsers());
    return () => {
      dispatch(showUserInfo([]));
    };
  }, []);

  useEffect(() => {

    if (successUpdate) {
      dispatch(showUserInfo([]));
      dispatch(listEnableUsers());
    }

    return () => {

    };
  }, [enableUserUpdate]);




  const handleToggle = (value, arry) => () => {

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    toogleButton(newChecked);
  };

  const toogleButton = (newData) => {
    if (newData.length > 0) {
      if (newData.length === 1) {
        let index = newData.indexOf(0);
        if (index > -1) {
          setDisabledSelect(true);
        } else {
          setDisabledSelect(false);
        }
      } else {
        setDisabledSelect(false);
      }
    }
  }

  const handleShowStudent = (value) => () => {
    let obj = users.find(o => o.id === value);


    // console.log("obj-detail");
    // console.log(obj);

    dispatch(showUserInfo(obj));
  };

  const executeScroll = () => {
    dispatch(updateEnableUsers(checked));
  };

  return (

    <>
      <div className={classes.root}>
        <h1>Habilitar el registro del usuario</h1>
        <div className={classes.roots}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <Paper className={classes.paper}>
                {loadingUsers ? (
                  <div>Obteniendo usuarios...</div>
                ) : errorListUsers ? (
                  <div>Ocurrió un error</div>
                ) : (
                      <>
                        < List className={classes.list}>
                          {users.map((value, index) => {
                            const labelId = `checkbox-list-label-${value.id}`;
                            return (
                              <ListItem key={value.id} role={undefined} dense button onClick={handleToggle(value.id)}>
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(value.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value.name}`} />
                                <ListItemSecondaryAction onClick={handleShowStudent(value.id)}>
                                  <IconButton edge="end" aria-label="comments">
                                    <VisibilityIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                        </List>

                        <Divider />
                        <h4 className={classes.rightAlng}>Total: {users.length} Usuarios</h4>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={disabledSelect}
                          onClick={executeScroll}
                          startIcon={<CheckIcon />}
                          className={classes.margin}>
                          Habilitar el registro
                      </Button>
                      </>
                    )}
              </Paper>
            </Grid>

            <Grid item sm={12} md={6}>
              <Paper className={classes.paper2}>
                <EnableUserDetailComponent />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
export default EnableUsersScreen;
