import {

    GET_SAVINGS_CONF_RESET,
    GET_SAVINGS_CONF_REQUEST,
    GET_SAVINGS_CONF_SUCCESS,
    GET_SAVINGS_CONF_FAIL,

    UPDATE_SAVINGS_CONF_RESET,
    UPDATE_SAVINGS_CONF_REQUEST,
    UPDATE_SAVINGS_CONF_SUCCESS,
    UPDATE_SAVINGS_CONF_FAIL,

} from '../constants/savingsConfConstants';

function getSavingsConfReducer(state = { conf: {} }, action) {
    switch (action.type) {
        case GET_SAVINGS_CONF_RESET:
            return { loading: false, conf: {}, success: false };
        case GET_SAVINGS_CONF_REQUEST:
            return { loading: true, conf: {} };
        case GET_SAVINGS_CONF_SUCCESS:
            return { loading: false, conf: action.payload, success: true };
        case GET_SAVINGS_CONF_FAIL:
            return { loading: false, conf: action.payload };
        default:
            return state;
    }
}

function saveSavingsConfReducer(state = { conf: {} }, action) {
    switch (action.type) {
        case UPDATE_SAVINGS_CONF_RESET:
            return { loading: false, conf: {}, success: false };
        case UPDATE_SAVINGS_CONF_REQUEST:
            return { loading: true, conf: {} };
        case UPDATE_SAVINGS_CONF_SUCCESS:
            return { loading: false, conf: action.payload, success: true };
        case UPDATE_SAVINGS_CONF_FAIL:
            return { loading: false, conf: action.payload };
        default:
            return state;
    }
}


export {
    getSavingsConfReducer,
    saveSavingsConfReducer,
};
