import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDataContactApi,
    updateDataContactApi,
    resetDataContactApi
} from '../actions/dataContactActions';

// MATERIAL UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiPhoneNumber from 'material-ui-phone-number';


function DataContactScreen(props) {

    const getDataContact = useSelector(state => state.getDataContact);
    const { loading: loadingGet, success: successGet, contact } = getDataContact;
    const saveDataContact = useSelector(state => state.saveDataContact);
    const { loading: loadingSave, success: successSave } = saveDataContact;

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [open, setOpen] = useState(false);
    const [phone, setPhone] = useState('');



    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: '#f5f5f5',
            minHeight: 'calc(100vh - 64px)',
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'right',
            color: theme.palette.text.secondary,
        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,

        },
        margin: {
            margin: theme.spacing(2),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        dispatch(getDataContactApi());
        return () => {
            dispatch(resetDataContactApi());
        };
    }, []);

    useEffect(() => {
        if (successGet) {
            setName(contact.name);
            setEmail(contact.email);
            setPhone(contact.phone);
        }
        return () => {

        };
    }, [getDataContact]);

    useEffect(() => {

        if (successSave) {
            setOpen(true);
        }

        return () => {

        };
    }, [saveDataContact]);

    const checkName = (v) => {
        if (!v || v.trim().length == 0) {
            setNameError('Este campo no debe estar vacío');
        } else {
            setNameError("");
        }
        setName(v)
    }

    const checkEmail = (v) => {
        if (!v || v.trim().length == 0) {
            setEmailError('Este campo no debe estar vacío');
        } else {
            setEmailError("");
        }
        setEmail(v)
    }

    const executeScroll = () => {
        if (name && email) {
            dispatch(updateDataContactApi({ name: name, email: email, phone: phone }));
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOnChange = (e) => {

        setPhone(e);
    }


    return (
        <>
            <div className={classes.root}>
                <h1>Administrar datos de la sección Contacto</h1>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <Paper className={classes.paper}>
                            <form className={classes.form} noValidate >
                                <TextField
                                    autoFocus
                                    value={name}
                                    error={nameError.length > 0}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Nombre completo"
                                    name="name"
                                    autoComplete="name"
                                    onChange={(e) => checkName(e.target.value)}
                                    helperText={nameError && nameError}
                                />
                                <TextField
                                    value={email}
                                    error={emailError.length > 0}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo electrónico"
                                    name="email"
                                    autoComplete="email"
                                    onChange={(e) => checkEmail(e.target.value)}
                                    helperText={emailError && emailError}
                                />
                                <br></br>
                                <br></br>
                                <MuiPhoneNumber
                                    defaultCountry={'mx'}
                                    fullWidth
                                    mask="(0)999 999 99 99"
                                    variant="outlined"
                                    value={phone}
                                    countryCodeEditable={false}
                                    onChange={handleOnChange} />


                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={nameError.length > 0 || emailError.length > 0}
                                    onClick={executeScroll}
                                    // startIcon={<CheckIcon />}
                                    className={classes.margin}>
                                    Modificar
                                </Button>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Backdrop className={classes.backdrop} open={loadingGet || loadingSave}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        La información ha sido almacenada de forma correcta.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}





export default DataContactScreen;
