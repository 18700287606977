import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    saveGroup,
    resetGroupSave,
    deleteGroup
} from '../actions/groupActions';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

function AddGroupComponent(props) {
    const dispatch = useDispatch();

    const [type, setType] = useState(props.type);

    const [id, setId] = useState(props.id);
    const [name, setName] = useState(props.name);
    const [description, setDescription] = useState(props.description);

    const [nameError, setNameError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');


    const groupSave = useSelector(state => state.groupSave);
    const { loading, error, success } = groupSave;

    useEffect(() => {
        return () => {
            dispatch(resetGroupSave());
        };
    }, []);


    useEffect(() => {
        if (error) {
            error.forEach(
                function myFunction(item, index) {
                    switch (item.param) {
                        case 'name':
                            setNameError(item.msg);
                            break;
                        case 'description':
                            setDescriptionError(item.msg);
                            break;
                        default:
                        // code block
                    }
                });
        }

        if (success) {
            props.onchange(false, 2);
        }

        return () => {
            //
        };
    }, [groupSave]);

    let textInput = useRef(null);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'right',
            color: theme.palette.text.secondary,
        },
        list: {
            width: '100%',
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        noSelected: {
            color: 'red',
        },
        selected: {
            color: 'green'
        },
        margin: {
            margin: theme.spacing(2),
        },
        cancel: {
            margin: theme.spacing(2),
            textAlign: 'left',
        },
        wrapper: {
            textAlign: 'right',
        }
    }));

    const classes = useStyles();


    // VALIDACIONES
    const checkName = (v) => {
        if (!v || v.trim().length === 0) {
            setNameError('Este campo no debe estar vacío');
        } else {
            setNameError("");
        }
        setName(v)
    }

    const checkDescription = (v) => {
        if (!v || v.trim().length === 0) {
            setDescriptionError('Este campo no debe estar vacío');
        } else {
            setDescriptionError("");
        }
        setDescription(v)
    }

    // FIN VALIDACIONES

    const closeModal = event => {
        props.onchange(false, 1);
    }

    // OPERACIONES CON ESTUDIANTES
    const submitStudentHandler = (e) => {
        e.preventDefault();

        if (type === 1) {
            dispatch(
                deleteGroup({
                    id
                })
            );
        } else {
            dispatch(
                saveGroup({
                    id,
                    name,
                    description,
                })
            );
        }

    };

    return (
        <>

            {type === 0 ? (

                <form
                    className={classes.form}
                    onSubmit={submitStudentHandler}
                    noValidate >

                    <TextField
                        autoFocus
                        inputRef={textInput}
                        value={name}
                        error={nameError.length > 0}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Nombre completo"
                        name="name"
                        autoComplete="name"
                        onChange={(e) => checkName(e.target.value)}
                        helperText={nameError && nameError}
                        size="small"
                    />
                    <TextField
                        value={description}
                        error={descriptionError.length > 0}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="description"
                        label="Descripción"
                        name="description"
                        autoComplete="description"
                        onChange={(e) => checkDescription(e.target.value)}
                        helperText={descriptionError && descriptionError}
                        size="small"
                    />

                    <div className={classes.wrapper}>
                        <Button
                            onClick={closeModal}
                            variant="outlined"
                            color="primary"
                            className={classes.cancel} >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Guardar
                        </Button>
                    </div>

                </form>

            ) : (
                <>
                    <div>Deseas borrar al grupo {name} ? <br></br><br></br><br></br></div>
                    <div className={classes.wrapper}>
                        <Button
                            onClick={closeModal}
                            variant="outlined"
                            color="primary"
                            className={classes.cancel} >
                            Cancelar
                        </Button>
                        <Button
                            onClick={submitStudentHandler}
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Confirmar
                        </Button>
                    </div>
                </>
            )}


            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default AddGroupComponent;
