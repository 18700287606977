import React from 'react';
import { useSelector } from 'react-redux';
import dateFormat from 'dateformat';


function EnableUserDetailComponent(props) {
    const enableUserDetail = useSelector(state => state.enableUserDetail);
    const { user } = enableUserDetail;

    const obtenerFechaConvertida = (fecha) => {
        let utcSeconds = fecha;
        let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds);

        return dateFormat(d, 'd"/"mm"/"yyyy  " "h:MM:ss TT');
    }

    return (
        <>
            <h3>Nombre completo: {user.name}</h3>
            <h3>Alias: {user.nickname}</h3>
            <h3>Correo electrónico: {user.email}</h3>
            <h3>Tipo de suscripción: {user.userPlan === 2 ? 'Premium' : 'Básico'}</h3>
            <h3>Fecha de solicitud: {user.timestamp ? obtenerFechaConvertida(user.timestamp._seconds) : ''}</h3>
        </>
    );
}

export default EnableUserDetailComponent;
