export const PRODUCTS_LIST_RESET = 'PRODUCTS_LIST_RESET';
export const PRODUCTS_LIST_REQUEST = 'PRODUCTS_LIST_REQUEST';
export const PRODUCTS_LIST_SUCCESS = 'PRODUCTS_LIST_SUCCESS';
export const PRODUCTS_LIST_FAIL = 'PRODUCTS_LIST_FAIL';

export const PRODUCT_MANAGEMENT_RESET = 'PRODUCT_MANAGEMENT_RESET';
export const PRODUCT_MANAGEMENT_REQUEST = 'PRODUCT_MANAGEMENT_REQUEST';
export const PRODUCT_MANAGEMENT_SUCCESS = 'PRODUCT_MANAGEMENT_SUCCESS';
export const PRODUCT_MANAGEMENT_FAIL = 'PRODUCT_MANAGEMENT_FAIL';


export const SAVE_PRODUCT_MANAGEMENT_RESET = 'SAVE_PRODUCT_MANAGEMENT_RESET';
export const SAVE_PRODUCT_MANAGEMENT_REQUEST = 'SAVE_PRODUCT_MANAGEMENT_REQUEST';
export const SAVE_PRODUCT_MANAGEMENT_SUCCESS = 'SAVE_PRODUCT_MANAGEMENT_SUCCESS';
export const SAVE_PRODUCT_MANAGEMENT_FAIL = 'SAVE_PRODUCT_MANAGEMENT_FAIL';


export const DELETE_PRODUCT_MANAGEMENT_RESET = 'DELETE_PRODUCT_MANAGEMENT_RESET';
export const DELETE_PRODUCT_MANAGEMENT_REQUEST = 'DELETE_PRODUCT_MANAGEMENT_REQUEST';
export const DELETE_PRODUCT_MANAGEMENT_SUCCESS = 'DELETE_PRODUCT_MANAGEMENT_SUCCESS';
export const DELETE_PRODUCT_MANAGEMENT_FAIL = 'DELETE_PRODUCT_MANAGEMENT_FAIL';


export const GET_CATEGORIES_RESET = 'GET_CATEGORIES_RESET';
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

export const GET_RANDOM_CONFIGURATION_RESET = 'GET_RANDOM_CONFIGURATION_RESET';
export const GET_RANDOM_CONFIGURATION_REQUEST = 'GET_RANDOM_CONFIGURATION_REQUEST';
export const GET_RANDOM_CONFIGURATION_SUCCESS = 'GET_RANDOM_CONFIGURATION_SUCCESS';
export const GET_RANDOM_CONFIGURATION_FAIL = 'GET_RANDOM_CONFIGURATION_FAIL';