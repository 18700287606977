export const GET_TEACHER_GROUP_LIST_RESET = 'GET_TEACHER_GROUP_LIST_RESET';
export const GET_TEACHER_GROUP_LIST_REQUEST = 'GET_TEACHER_GROUP_LIST_REQUEST';
export const GET_TEACHER_GROUP_LIST_SUCCESS = 'GET_TEACHER_GROUP_LIST_SUCCESS';
export const GET_TEACHER_GROUP_LIST_FAIL = 'GET_TEACHER_GROUP_LIST_FAIL';

export const UPDATE_TEACHER_GROUP_LIST_RESET = 'UPDATE_TEACHER_GROUP_LIST_RESET';
export const UPDATE_TEACHER_GROUP_LIST_REQUEST = 'UPDATE_TEACHER_GROUP_LIST_REQUEST';
export const UPDATE_TEACHER_GROUP_LIST_SUCCESS = 'UPDATE_TEACHER_GROUP_LIST_SUCCESS';
export const UPDATE_TEACHER_GROUP_LIST_FAIL = 'UPDATE_TEACHER_GROUP_LIST_FAIL';

export const SAVE_TEACHER_RESET = "SAVE_TEACHER_RESET";
export const SAVE_TEACHER_REQUEST = "SAVE_TEACHER_REQUEST";
export const SAVE_TEACHER_SUCCESS = "SAVE_TEACHER_SUCCESS";
export const SAVE_TEACHER_FAIL = "SAVE_TEACHER_FAIL";