export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_SAVE_SHIPPING = 'CART_SAVE_SHIPPING';
export const CART_SAVE_PAYMENT = 'CART_SAVE_PAYMENT';


export const GET_CHECK_BALANCE_RESET = "GET_CHECK_BALANCE_RESET";
export const GET_CHECK_BALANCE_REQUEST = "GET_CHECK_BALANCE_REQUEST";
export const GET_CHECK_BALANCE_SUCCESS = "GET_CHECK_BALANCE_SUCCESS";
export const GET_CHECK_BALANCE_FAIL = "GET_CHECK_BALANCE_FAIL";


export const TRANSACTION_OK = "TRANSACTION_OK";
export const TRANSACTION_RESET = "TRANSACTION_RESET";


export const GET_ORDER_USER_RESET = "GET_ORDER_USER_RESET";
export const GET_ORDER_USER_REQUEST = "GET_ORDER_USER_REQUEST";
export const GET_ORDER_USER_SUCCESS = "GET_ORDER_USER_SUCCESS";
export const GET_ORDER_USER_FAIL = "GET_ORDER_USER_FAIL";


export const ADD_PRODUCT_COMMENT_RESET = "ADD_PRODUCT_COMMENT_RESET";
export const ADD_PRODUCT_COMMENT_REQUEST = "ADD_PRODUCT_COMMENT_REQUEST";
export const ADD_PRODUCT_COMMENT_SUCCESS = "ADD_PRODUCT_COMMENT_SUCCESS";
export const ADD_PRODUCT_COMMENT_FAIL = "ADD_PRODUCT_COMMENT_FAIL";

