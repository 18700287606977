import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dateFormat from 'dateformat';

import TeacherComponent from '../components/TeacherComponent';
import NumberFormat from 'react-number-format';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiAlert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import StarBorder from '@material-ui/icons/StarBorder';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import {
    resetBuyCreditListApi,
    getBuyCreditListApi,
    getJustificationsApi,
    updateBuyCreditApi,
    resetUpdateBuyCreditApi,
    updateAllocateBalanceApi
} from '../actions/buyCreditActions';

dateFormat.i18n = {
    dayNames: [
        'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab',
        'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'
    ],
    monthNames: [
        'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic',
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ],
    timeNames: [
        'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
    ]
};

function AllocateBalanceScreen(props) {

    const [state] = useState({
        vertical: 'bottom',
        horizontal: 'center',
    });

    const [values, setValues] = useState({
        textmask: '(1  )    -    ',
        numberformat: '',
    });

    const { vertical, horizontal } = state;

    const [reasonId, setReasonId] = useState('');
    const [reasonName, setReasonName] = useState('');

    const buyCreditList = useSelector(state => state.buyCreditList);
    const { loading: loadingGet, users: usersGet, error: errorGet, success: successGet } = buyCreditList;


    const justificationsList = useSelector(state => state.justificationsList);
    const { loading: loadingJustifications, justifications, error: errorJustifications, success: successJustifications } = justificationsList;


    const updateBuyCredit = useSelector(state => state.updateBuyCredit);
    const { loading: loadingUpdate, user: userUpdate, error: errorUpdate, success: successUpdate } = updateBuyCredit;

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;


    const [disabledSelect, setDisabledSelect] = useState(true);
    const [teacherChecked, setTeacherChecked] = useState([0]);
    const [showBackdrop, setShowBackdrop] = useState(true);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showModal, setShowModal] = useState(false);


    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [nickname, setNickname] = useState('');
    const [balance, setBalance] = useState(0);
    const [historical, setHistorical] = useState([]);
    const [achievements, setAchievements] = useState([]);
    const [balanceAchievements, setBalanceAchievements] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);


    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getJustificationsApi());
        dispatch(getBuyCreditListApi());

        return () => {
            //
            dispatch(resetBuyCreditListApi());
            dispatch(resetUpdateBuyCreditApi());
        };
    }, []);

    useEffect(() => {
        if (loadingGet) {
            setShowBackdrop(true);
        } else {
            setShowBackdrop(false);
        }
        return () => {
            //
        };
    }, [buyCreditList]);



    useEffect(() => {
        setShowModal(false);
        if (loadingUpdate) {
            setShowBackdrop(true);
        } else {
            setShowBackdrop(false);
        }

        if (successUpdate) {
            setTeacherChecked([0]);
            resetAllinfo();
            setOpenSnackbar(true);
            dispatch(getBuyCreditListApi());
        }

        return () => {
            //
        };
    }, [updateBuyCredit]);




    // Configuración de estilos
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: '#f5f5f5',
            minHeight: 'calc(100vh - 64px)',
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 350,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        noSelected: {
            color: 'red',
        },
        selected: {
            color: 'green'
        },
        margin: {
            margin: theme.spacing(2),
        },
        cancel: {
            margin: theme.spacing(2),
            textAlign: 'left',
        },
        radios: {
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(2),
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 350,
        },
        demo: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 450,
            paddingLeft: theme.spacing(3),
            paddingTop: theme.spacing(2),
            // paddingBottom: theme.spacing(0),
        },
        disabledInput: {
            "& .MuiInputBase-root.Mui-disabled": {
                color: "black"
            }
        },
        margenSelect: {
            marginTop: theme.spacing(2),
        }
    }));

    const classes = useStyles();

    const handleSelectTeacher = (id) => () => {

        const currentIndex = teacherChecked.indexOf(id);
        let newChecked = [...teacherChecked]; // Se crea el nuevo array con los datos que y existen

        if (currentIndex === -1) {
            newChecked = [0];
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setTeacherChecked(newChecked);
        // setCheckedGroup([0]);
        // iterateGroupsArry(newChecked);
        toogleButtonAsign(newChecked);


    };


    const resetAllinfo = () => {
        setReason('');
        showUserInfo(false, []);
        setDisabledSelect(true);
    }

    const toogleButtonAsign = (newChecked) => {
        if (newChecked.length > 0) {
            if (newChecked.length === 1) {
                let index = newChecked.indexOf(0);
                if (index > -1) {
                    setDisabledSelect(true);
                    showUserInfo(false, []);
                } else {
                    setDisabledSelect(false);
                    showUserInfo(true, newChecked[1]);
                }
            } else {
                setDisabledSelect(false);
                showUserInfo(true, newChecked[1]);
            }
        }
    }

    const showUserInfo = (show, id) => {
        if (show) {

            if (id) {
                let obj = usersGet.find(o => o.id === id);
                if (obj.hasOwnProperty('historicalBalance')) {
                    setHistorical(obj.historicalBalance);
                } else {
                    setHistorical([]);
                }
                setId(obj.id);
                setName(obj.name);
                setEmail(obj.email);
                setNickname(obj.nickname);
                setBalance(obj.balance);
                setBalanceAchievements(obj.balanceAchievements);

                if (obj.hasOwnProperty('achievements')) {
                    setAchievements(obj.achievements);
                } else {
                    setAchievements([]);
                }

            }


        } else {
            setId('');
            setName('');
            setEmail('');
            setNickname('');
            setBalance(0);
            setHistorical([]);
            setBalanceAchievements(0);
            setAchievements([]);
        }
    }


    const handleDoassign = () => {

        let finalCount = 0;

        // Primero que 
        if (customAmount > 0) {
            finalCount = customAmount;
        } else {
            finalCount = amount;
        }

        if (customAmount === "0.00") {
            setCustomAmountError("Debe ser mayor a cero");
            finalCount = 0;
        }

        if (reasonId === "") {
            setReasonError("Debe justificar la asignación");
            finalCount = 0;
        }

        if (finalCount > 0) {
            setFinalAmount(finalCount);
            setShowModal(true);
            // Do distpatch
            // e.preventDefault();
            // dispatch(updateTeacherGroupListApi(teacherChecked, checkedGroup))
        }

    };

    // Cerrar el snackbar
    const handlerCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [amount, setAmount] = useState('10');

    const [customAmount, setCustomAmount] = useState(0);
    const [customAmountError, setCustomAmountError] = useState('');

    const [reason, setReason] = useState('');
    const [reasonError, setReasonError] = useState('');

    const handleChangeAmount = (event) => {
        setAmount(event.target.value);
    };

    const handleChange = (event) => {

        if (event.target.value > 100) {
            setCustomAmountError("Límite sobrepasado, no debe asignar más de 100 BitCoins");
        } else {
            setCustomAmountError("");
            if (event.target.value === "0.00") {
                setCustomAmountError("Debe ser mayor a cero");
            }
            if (event.target.value >= 0) {
                setCustomAmount(event.target.value);
                setValues({
                    ...values,
                    [event.target.name]: event.target.value,
                });
            }
        }
    };

    const checkReason = (v) => {
        if (!v || v.trim().length === 0) {
            setReasonError('Debe justificar la asignación');
        } else {
            setReasonError("");
        }
        setReason(v)
    }


    const handleSendBalance = () => {
        dispatch(updateAllocateBalanceApi({ manager: userInfo._id, user: id, reason: reasonName, amount: finalAmount, reasonId: reasonId }));
    };


    const obtenerFechaConvertida = (fecha) => {
        let utcSeconds = fecha;
        let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds);

        return dateFormat(d, 'd"/"mm"/"yyyy  " "h:MM:ss TT');
    }


    // Cerrar el snackbar
    const CloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleChangeReason = (event) => {
        // let incidenceSelected = event.target.value;
        // if (incidenceSelected) {
        // setIncidenceError('');
        // } else {
        //   setIncidenceError('Debe seleccionar un tipo de incidencia');
        // }
        setReasonError("");

        let reasonData = justifications.find(o => o.id === event.target.value);
                                
        setReasonName(reasonData.title);
        setReasonId(event.target.value);
    };



    return (
        <>
            <div className={classes.root}>
                <h1>Asignar saldo por logros</h1>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={5}>

                        <Paper className={classes.paper}>
                            {loadingGet ? (
                                <div>Obteniendo alumnos...</div>
                            ) : errorGet ? (
                                <div>Ocurrió un error</div>
                            ) : (
                                <>
                                    <h3>Seleccionar Alumno</h3>
                                    <Divider />
                                    < List className={classes.list}>
                                        {usersGet && usersGet.map((value, index) => {
                                            const labelId = `checkbox-list-label-${value.id}`;

                                            return (

                                                <ListItem key={value.id} role={undefined} dense button onClick={handleSelectTeacher(value.id)}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={teacherChecked.indexOf(value.id) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={`${value.name} - ( \u20BF ${value.balance.toFixed(2)} )`} />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item sm={12} md={2}>
                        <Paper className={classes.paper}>

                            <Grid
                                container
                                spacing={0}
                                alignItems="center"
                                justify="center"
                                style={{ minHeight: "396px" }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={disabledSelect}
                                    className={classes.button}
                                    startIcon={<Icon>arrow_back</Icon>}
                                    onClick={handleDoassign}
                                >
                                    Asignar
                                </Button>
                            </Grid>

                        </Paper>
                    </Grid>
                    <Grid item sm={12} md={5}>
                        <Paper className={classes.paper}>

                            {loadingGet ? (
                                <div>Obteniendo Montos...</div>
                            ) : errorGet ? (
                                <div>Ocurrió un error</div>
                            ) : (
                                <>
                                    <h3>Asignar BitCoins/Monedero</h3>
                                    <Divider />
                                    <FormControl className={classes.radios} component="fieldset" disabled={disabledSelect || customAmount.length > 0} >
                                        <RadioGroup aria-label="amount" name="amount" value={amount} onChange={handleChangeAmount}>
                                            <FormControlLabel value="10" control={<Radio />} label={"\u20BF $10.00"} />
                                            <FormControlLabel value="15" control={<Radio />} label={"\u20BF $15.00"} />
                                            <FormControlLabel value="50" control={<Radio />} label={"\u20BF $50.00"} />
                                        </RadioGroup>
                                    </FormControl>

                                </>
                            )}
                            <br></br>
                            <br></br>
                            <TextField
                                error={customAmountError.length > 0}
                                helperText={customAmountError && customAmountError}
                                disabled={disabledSelect}
                                label="Otra cantidad (No mayor a 100 BitCoins)"
                                fullWidth
                                value={values.numberformat}
                                onChange={handleChange}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                variant="outlined"
                            />


                            {/* <TextField
                                error={reasonError.length > 0}
                                helperText={reasonError && reasonError}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                value={reason}
                                disabled={disabledSelect}
                                multiline
                                rows={4}
                                onChange={(e) => checkReason(e.target.value)}
                                id="reason"
                                label="Justificación"
                                name="reason"
                                autoComplete="reason"
                                size="small"
                            /> */}
                            {/* <br></br> */}
                            {/* <Select
                                label="Justificación"
                                labelId="demo-simple-select-label1234"
                                id="demo-simple-select2"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                disabled={disabledSelect}
                                value={categoryId}
                                onChange={handleChange}
                            >
                                <MenuItem value="all">Todos</MenuItem>

                                {justifications && justifications.map((value, index) => {
                                    return (
                                        <MenuItem key={value.id} value={value.id}>{value.title}</MenuItem>
                                    );
                                })}

                            </Select> */}


                            <FormControl variant="outlined" className={classes.margenSelect} fullWidth>
                                <InputLabel id="demo-simple-select-outlined-label">Justificación</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={reasonId}
                                    onChange={handleChangeReason}
                                    disabled={disabledSelect}
                                    label="Justificación"
                                >
                                    {/* <MenuItem value="">
                                        <em>Selecciona una justificación</em>
                                    </MenuItem> */}
                                    {justifications && justifications.map((value, index) => {
                                        return (
                                            <MenuItem key={value.id} value={value.id} name={value.title}>{value.title}</MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText>{reasonError}</FormHelperText>
                            </FormControl>



                        </Paper>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Paper className={classes.paper}>
                            <h3>Información del usuario</h3>

                            <TextField
                                className={classes.disabledInput}
                                margin="normal"
                                fullWidth
                                value={name}
                                disabled
                                id="name"
                                label="Nombre"
                                name="name"
                                autoComplete="name"
                            />
                            <TextField
                                className={classes.disabledInput}
                                margin="normal"
                                fullWidth
                                value={email}
                                disabled
                                id="email"
                                label="Correo"
                                name="email"
                                autoComplete="email"
                            />
                            <TextField
                                className={classes.disabledInput}
                                margin="normal"
                                fullWidth
                                value={nickname}
                                disabled
                                id="nickname"
                                label="Alias"
                                name="nickname"
                                autoComplete="nickname"
                            /> <br></br><br></br>
                            <TextField
                                disabled
                                label="Saldo actual"
                                fullWidth
                                value={balance}
                                name="balance"
                                id="formatted-numberformat-input"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                className={classes.disabledInput}
                            />
                            <br></br>
                            <br></br>
                            <br></br>
                            <h3>Historial de asignaciones del usuario</h3>
                            <Divider />
                            {historical && historical.map((value, index) => {

                                return (
                                    <>
                                        {value.type === "win" ? (
                                            <List dense={true} className={classes.demo}>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={
                                                            <Typography type="body2" style={
                                                                { color: '#000000' }
                                                            }>{'\u20BF ' + value.amount.toFixed(2)}</Typography>
                                                        }
                                                        secondary={
                                                            <Typography>
                                                                {'Fecha: ' + obtenerFechaConvertida(value.date._seconds)} <br></br>
                                                                {'Asignó: ' + value.managerName} <br></br>
                                                                {'Justificación: ' + value.reason}
                                                            </Typography>}
                                                    />
                                                </ListItem>
                                            </List>
                                        ) : (
                                            null
                                        )}
                                    </>
                                )
                            })}

                        </Paper>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Paper className={classes.paper}>
                            <h3>Insignias ganadas </h3>
                            <h4>** {200 - balanceAchievements} BitCoins para desbloquear la siguiente insignia **</h4>
                            <Divider />

                            {achievements && achievements.map((value, index) => {
                                return (
                                    <>
                                        <List dense={true} className={classes.demo}>


                                            {/* <ListItem>
                                            <ListItemText
                                                primary={
                                                    <Typography type="body2" style={
                                                        { color: '#000000' }
                                                    }>{'\u20BF ' + value.amount.toFixed(2)}</Typography>
                                                }
                                                secondary={
                                                    <Typography>
                                                        {'Fecha: ' + obtenerFechaConvertida(value.date._seconds)} <br></br>
                                                        {'Asignó: ' + value.managerName} <br></br>
                                                        {'Jutificación: ' + value.reason}
                                                    </Typography>}
                                            />
                                        </ListItem> */}

                                            <ListItem>
                                                <ListItemAvatar>
                                                    <img width="50" height="64" src={`/images/insignia1.png`} alt={"ddd"} />
                                                    {/* <Avatar
                                                    variant="square"
                                                    src={`/images/escudo.png`}>
                                                </Avatar> */}
                                                </ListItemAvatar>
                                                <ListItemText primary={'\u00A0\u00A0' + value.name} secondary={'\u00A0\u00A0' + obtenerFechaConvertida(value.date._seconds)} />
                                            </ListItem>




                                        </List>

                                    </>
                                )
                            })}




                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Backdrop className={classes.backdrop} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={showSnackbar}
                autoHideDuration={6000}
                // onClose={CloseSnackbar}
                // message="Se han realizado los cambios solicitados"
                key={vertical + horizontal}>
                <Alert onClose={handlerCloseSnackbar} severity="success">
                    Se han realizado los cambios solicitados
                </Alert>
            </Snackbar>

            <Dialog
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Asignación de BitCoins</DialogTitle>
                <DialogContent>
                    Está a punto de asignar <b>{finalAmount}</b>  BitCoins a <b>{name} ({email})</b><br></br>
                    Justificación: <b>{reasonName}</b><br></br>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>
                        Cancelar
                    </Button>
                    <Button onClick={handleSendBalance} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>

            </Dialog>


            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={CloseSnackbar}
                message="Se han realizado los cambios solicitados"
                key={vertical + horizontal}>
                <Alert onClose={CloseSnackbar} severity="success">
                    La asignación ha sido exitosa
                </Alert>
            </Snackbar>

        </>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix={'\u20BF '}
            decimalScale={2}
            fixedDecimalScale={true}
        />
    );
}

export default AllocateBalanceScreen;
