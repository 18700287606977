import {
    GENERAL_SCHOOL_INFORMATION_RESET,
    GENERAL_SCHOOL_INFORMATION_REQUEST,
    GENERAL_SCHOOL_INFORMATION_SUCCESS,
    GENERAL_SCHOOL_INFORMATION_FAIL,
} from "../constants/generalSchoolInformationConstants";

import {
    GROUP_ACTIONS_URL,
    SCHOOLINFORMATION
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;

const setGeneralSchoolInformationApi = (subdomain) => (dispatch) => {
    let schoolInfo = SCHOOLINFORMATION(subdomain);
    dispatch({ type: GENERAL_SCHOOL_INFORMATION_SUCCESS, payload: schoolInfo });
}

export {
    setGeneralSchoolInformationApi
}