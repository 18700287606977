import {
    GENERAL_SCHOOL_INFORMATION_RESET,
    GENERAL_SCHOOL_INFORMATION_REQUEST,
    GENERAL_SCHOOL_INFORMATION_SUCCESS,
    GENERAL_SCHOOL_INFORMATION_FAIL,
} from "../constants/generalSchoolInformationConstants";

function getGeneralSchoolInformationReducer(state = { info: {} }, action) {
    switch (action.type) {
        case GENERAL_SCHOOL_INFORMATION_RESET:
            return { loading: false, success: false };
        case GENERAL_SCHOOL_INFORMATION_REQUEST:
            return { loading: true, info: {}, success: false };
        case GENERAL_SCHOOL_INFORMATION_SUCCESS:
            return { loading: false, info: action.payload, success: true };
        case GENERAL_SCHOOL_INFORMATION_FAIL:
            return { loading: false, error: action.payload, info: {}, success: false };
        default:
            return state;
    }
}

export {
    getGeneralSchoolInformationReducer,
};