import {
  GET_BUY_CREDIT_LIST_RESET,
  GET_BUY_CREDIT_LIST_REQUEST,
  GET_BUY_CREDIT_LIST_SUCCESS,
  GET_BUY_CREDIT_LIST_FAIL,

  UPDATE_BUY_CREDIT_RESET,
  UPDATE_BUY_CREDIT_REQUEST,
  UPDATE_BUY_CREDIT_SUCCESS,
  UPDATE_BUY_CREDIT_FAIL,

  GET_JUSTIFICATIONS_LIST_RESET,
  GET_JUSTIFICATIONS_LIST_REQUEST,
  GET_JUSTIFICATIONS_LIST_SUCCESS,
  GET_JUSTIFICATIONS_LIST_FAIL,

  SAVE_JUSTIFICATION_REQUEST,
  SAVE_JUSTIFICATION_SUCCESS,
  SAVE_JUSTIFICATION_FAIL


} from '../constants/buyCreditConstants';

import {
  GROUP_ACTIONS_URL
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;


const resetBuyCreditListApi = () => (dispatch) => {
  dispatch({ type: GET_BUY_CREDIT_LIST_RESET });
}


const getJustificationsApi = () => async (dispatch, getState) => {

  dispatch({ type: GET_JUSTIFICATIONS_LIST_REQUEST });
  const { userSignin: { userInfo } } = getState();


  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/product/justifications'
    , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: GET_JUSTIFICATIONS_LIST_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: GET_JUSTIFICATIONS_LIST_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: GET_JUSTIFICATIONS_LIST_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

};


// DeleteJustification
const deleteJustification = (student) => async (dispatch, getState) => {

  try {
    dispatch({ type: SAVE_JUSTIFICATION_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    if (student.id) {

      await fetch(
        urlBase +
        'user/justification'
        , {
          method: 'DELETE',
          body: JSON.stringify({
            id: student.id,
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userInfo.token
          }
        })
        .then(function (response) {
          if (response.ok) {
            ok = true;
          } else if (response.status === 400) {
            err400 = true;
          } else {
            // unknown = true;
          }
          return response.json();
        })
        .then(function (data) {
          // console.log('data => ', data);
          msg = data;
        })
        .catch(function (err) {
          console.error(err);
          // unknown = true;
        });

    }

    if (ok) {
      dispatch({ type: SAVE_JUSTIFICATION_SUCCESS, payload: msg });
    } else if (err400) {
      alert("No se puede borrar: " + msg.errors[0].msg);
      dispatch({ type: SAVE_JUSTIFICATION_FAIL, payload: msg.errors });
    } else {
      alert("Error desconocido, intenta más tarde");
      dispatch({ type: SAVE_JUSTIFICATION_FAIL, payload: [{ param: 'default', msg: "Ocurrió un error, intenta más tarde" }] });
    }

  } catch (error) {
    dispatch({ type: SAVE_JUSTIFICATION_FAIL, payload: [{ msg: error.message }] });
  }

};


// SaveJustification
const saveJustification = (sender) => async (dispatch, getState) => {


  try {
    dispatch({ type: SAVE_JUSTIFICATION_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    if (!sender.id) {

      await fetch(
        urlBase +
        'user/justification'
        , {
          method: 'POST',
          body: JSON.stringify({
            title: sender.title
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userInfo.token
          }
        })
        .then(function (response) {
          if (response.ok) {
            ok = true;
          } else if (response.status === 400) {
            err400 = true;
          } else {
            // unknown = true;
          }
          return response.json();
        })
        .then(function (data) {
          // console.log('data => ', data);
          msg = data;
        })
        .catch(function (err) {
          console.error(err);
          // unknown = true;
        });

    } else {


      await fetch(
        urlBase +
        'user/justification'
        , {
          method: 'PUT',
          body: JSON.stringify({
            title: sender.title,
            id: sender.id
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userInfo.token
          }
        })
        .then(function (response) {
          if (response.ok) {
            ok = true;
          } else if (response.status === 400) {
            err400 = true;
          } else {
            // unknown = true;
          }
          return response.json();
        })
        .then(function (data) {
          // console.log('data = ', data);
          msg = data;
        })
        .catch(function (err) {
          console.error(err);
          // unknown = true;
        });

    }

    if (ok) {
      dispatch({ type: SAVE_JUSTIFICATION_SUCCESS, payload: msg });
    } else if (err400) {
      dispatch({ type: SAVE_JUSTIFICATION_FAIL, payload: msg.errors });
    } else {
      alert("Error desconocido, intenta más tarde");
      dispatch({ type: SAVE_JUSTIFICATION_FAIL, payload: [{ param: 'default', msg: "Ocurrió un error, intenta más tarde" }] });
    }

  } catch (error) {
    dispatch({ type: SAVE_JUSTIFICATION_FAIL, payload: [{ msg: error.message }] });
  }

};


const getBuyCreditListApi = (
  category = '',
  searchKeyword = '',
  sortOrder = '',
  id = ''
) => async (dispatch, getState) => {

  dispatch({ type: GET_BUY_CREDIT_LIST_REQUEST });
  const { userSignin: { userInfo } } = getState();

  if (userInfo.roles.teacher) {
    id = userInfo._id;
  }

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/buycredit?category=' +
    category +
    '&searchKeyword=' +
    searchKeyword +
    '&sortOrder=' +
    sortOrder +
    '&id=' +
    id
    , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: GET_BUY_CREDIT_LIST_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: GET_BUY_CREDIT_LIST_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: GET_BUY_CREDIT_LIST_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

};


const updateBuyCreditApi = (data) => async (dispatch, getState) => {

  dispatch({ type: UPDATE_BUY_CREDIT_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/buycredit'
    , {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: UPDATE_BUY_CREDIT_SUCCESS, payload: msg });
  } else if (err400) {
    alert("Ocurrió un error, intenta más tarde");
    dispatch({ type: UPDATE_BUY_CREDIT_FAIL, payload: msg.errors });
  } else {
    alert("Ocurrió un error, intenta más tarde");
    dispatch({ type: UPDATE_BUY_CREDIT_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}


const updateAllocateBalanceApi = (data) => async (dispatch, getState) => {

  dispatch({ type: UPDATE_BUY_CREDIT_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/allocatebalance'
    , {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: UPDATE_BUY_CREDIT_SUCCESS, payload: msg });
  } else if (err400) {
    alert("Ocurrió un error, intenta más tarde");
    dispatch({ type: UPDATE_BUY_CREDIT_FAIL, payload: msg.errors });
  } else {
    alert("Ocurrió un error, intenta más tarde");
    dispatch({ type: UPDATE_BUY_CREDIT_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}



const resetUpdateBuyCreditApi = () => (dispatch) => {
  dispatch({ type: UPDATE_BUY_CREDIT_RESET });
}

export {
  resetBuyCreditListApi,
  getBuyCreditListApi,
  updateBuyCreditApi,
  resetUpdateBuyCreditApi,
  updateAllocateBalanceApi,
  getJustificationsApi,
  // deleteBuyCreditApi
  saveJustification,
  deleteJustification
};
