import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from 'dateformat';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CreateIcon from '@material-ui/icons/Create';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import BannerPartners from '../components/BannerPartners';
import {
  getBalanceHistoryApi,
  resetBalanceHistoryApi
} from '../actions/balanceHistoryActions';

function BalanceHistoryScreen(props) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [balance, setBalance] = useState(0);

  const [currentSavings, setCurrentSavings] = useState(0);

  const [achievements, setAchievements] = useState([]);
  const [savings, setSavings] = useState([]);
  const [cuts, setCuts] = useState([]);
  const [balanceAchievements, setBalanceAchievements] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const getBalanceHistory = useSelector(state => state.getBalanceHistory);
  const { loading, success, student, error } = getBalanceHistory;

  const [showBackdrop, setShowBackdrop] = useState(true);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBalanceHistoryApi());
    return () => {
      //
      dispatch(resetBalanceHistoryApi());
    };
  }, []);


  useEffect(() => {

    if (loading) {
      setShowBackdrop(true);
    } else {
      setShowBackdrop(false);
    }

    if (success) {

      setName(student.name);
      setEmail(student.email);
      setNickname(student.nickname);
      setBalance(student.balance);
      setCurrentSavings(student.currentSavings);
      setBalanceAchievements(student.balanceAchievements);

      if (student.hasOwnProperty('achievements')) {
        setAchievements(student.achievements);
        setSavings(student.savings)
      } else {
        setAchievements([]);
      }

      // console.log(student);
    }
    return () => {
      //
    };
  }, [getBalanceHistory]);


  // Configuración de estilos
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      backgroundColor: '#f5f5f5',
      height: 'calc(100vh - 64px)',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    list: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 350,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    noSelected: {
      color: 'red',
    },
    selected: {
      color: 'green'
    },
    margin: {
      margin: theme.spacing(2),
    },
    cancel: {
      margin: theme.spacing(2),
      textAlign: 'left',
    },
    radios: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 350,
    },
    demo: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 450,
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(2),
      // paddingBottom: theme.spacing(0),
    },
    disabledInput: {
      "& .MuiInputBase-root.Mui-disabled": {
        color: "black"
      }
    }
  }));

  const classes = useStyles();


  const obtenerFechaConvertida = (fecha) => {
    let utcSeconds = fecha;
    let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds);

    return dateFormat(d, 'd"/"mm"/"yyyy  " "h:MM:ss TT');
  }


  const handleOpenModal = (arryTmp) => () => {

    // 0 Nuevo profesor
    // 1 Actualización profesor
    // 2 Eliminación profesor

    console.log("arryTmp");
    console.log(arryTmp);

    setCuts(arryTmp);

    setShowModal(true);

  };


  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>

      <div className={classes.root}>
        <h1>Consulta de Saldo</h1>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Paper className={classes.paper}>
              <TextField
                margin="normal"
                fullWidth
                value={name}
                disabled
                id="name"
                label="Nombre"
                name="name"
                autoComplete="name"
                className={classes.disabledInput}
              />
              <TextField
                margin="normal"
                fullWidth
                value={email}
                disabled
                id="email"
                label="Correo"
                name="email"
                autoComplete="email"
                className={classes.disabledInput}
              />
              <TextField
                margin="normal"
                fullWidth
                value={nickname}
                disabled
                id="nickname"
                label="Alias"
                name="nickname"
                className={classes.disabledInput}
                autoComplete="nickname"
              /> <br></br><br></br>
              <TextField
                disabled
                label="Saldo actual"
                fullWidth
                value={balance}
                name="balance"
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                className={classes.disabledInput}
              /> <br></br><br></br>
              <TextField
                disabled
                label="Ahorro actual"
                fullWidth
                value={currentSavings}
                name="currentsavings"
                id="formatted-numberformat-input-savings"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                className={classes.disabledInput}
              />

            </Paper>
          </Grid>

          <Grid item sm={12} md={6}>
            <Paper className={classes.paper}>
              <h3>Insignias ganadas </h3>
              <h4>** {200 - balanceAchievements} BitCoins para desbloquear la siguiente insignia **</h4>
              <Divider />

              {achievements && achievements.map((value, index) => {
                return (
                  <>
                    <List dense={true} className={classes.demo}>
                      <ListItem>
                        <ListItemAvatar>
                          <img width="50" height="64" src={`/images/insignia1.png`} alt={"ddd"} />
                        </ListItemAvatar>
                        <ListItemText primary={'\u00A0\u00A0' + value.name} secondary={'\u00A0\u00A0' + obtenerFechaConvertida(value.date._seconds)} />
                      </ListItem>
                    </List>
                  </>
                )
              })}
            </Paper>
          </Grid>

          <Grid item sm={12} md={6}>
            <Paper className={classes.paper}>
              <h3>Historial de ahorros </h3>
              <Divider />

              {savings && savings.map((value, index) => {
                return (
                  <>
                    <List dense={true} className={classes.demo}>
                      <ListItem>
                        <ListItemAvatar>
                          <img width="58" height="58" src={`https://cdn4.iconfinder.com/data/icons/VISTA/accounting/png/256/deposit.png`} alt={"ddd"} />
                        </ListItemAvatar>

                        {/* <ListItemText

                          primary={
                            '\u00A0\u00A0\u20BF ' + value.amount
                          }
                          secondary={
                            '\u00A0\u00A0' +
                            "Fecha de depósito: " + ((value.isTransfered) ? obtenerFechaConvertida(value.DateTranfered._seconds) : "Pendiente")
                          } /> */}

                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                {/* {'\u00A0'}Ahorro actualizado: {value.total} */}
                                {'\u00A0\u00A0\u00A0'}Ahorro actual: {'\u20BF'} {((value.isTransfered) ? "0.00" : parseFloat(value.amount).toFixed(2))}
                              </Typography><br></br>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                {/* {'\u00A0'}Ahorro actualizado: {value.total} */}
                                {'\u00A0\u00A0\u00A0'}Depósito a Saldo: {'\u20BF'} {((value.isTransfered) ? parseFloat(value.amount).toFixed(2) : "0.00")}
                              </Typography>
                              {/* <span>{'\u00A0'}Porcentaje aplicado: {value.percent}%</span> */}
                              {/* <span>{'\u00A0\u00A0\u00A0'}Saldo depositado: </span> */}
                            </React.Fragment>
                          }
                          secondary={
                            // {/* "Fecha de depósito: " + ((value.isTransfered) ? obtenerFechaConvertida(value.DateTranfered._seconds) : "Pendiente") */ }

                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                {'\u00A0\u00A0\u00A0'}<b>Periodo de Ahorro:</b>
                              </Typography>
                              <br></br>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                {'\u00A0\u00A0\u00A0'}Fecha Inicio: {obtenerFechaConvertida(value.startDate._seconds)}
                              </Typography>
                              <br></br>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                {'\u00A0\u00A0\u00A0'}Fecha Fin: {obtenerFechaConvertida(value.endDate._seconds)}
                              </Typography>
                            </React.Fragment>


                          }
                        />

                        <ListItemSecondaryAction >
                          <IconButton edge="end"
                            onClick={handleOpenModal(value.profitHistory)}
                            aria-label="comments">
                            <VisibilityIcon />
                          </IconButton>
                        </ListItemSecondaryAction>

                      </ListItem>
                    </List>

                    <Divider />

                  </>
                )
              })}
            </Paper>
          </Grid>

        </Grid>
        {/* <BannerPartners /> */}
        <div id="cfb"  >
          <img style={{ width: 'calc(100%)' }} className="topb" src="/images/Banner1.jpg" />
          <img style={{ width: 'calc(100%)' }} className="bottomb" src="/images/Banner2.jpg" />
        </div>

        <Backdrop className={classes.backdrop} open={showBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>


        <Dialog
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Detalles de los cortes</DialogTitle>
          <DialogContent>

            {cuts && cuts.length > 0 ? (
              <></>
            ) : (<>
              <h2>Aún no existen movimientos</h2>
            </>)}

            {cuts && cuts.map((value, index) => {

              let indexItem = 0 + 49;

              return (
                <>

                  <Divider />

                  <List dense={true} className={classes.demo}>
                    <ListItem>
                      <ListItemAvatar>
                        <img width="45" height="45" src={`https://image.freepik.com/free-icon/timetable_318-1848.jpg`} alt={"ddd"} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              {'\u00A0'}Ahorro actualizado: {'\u20BF'} {parseFloat(value.total).toFixed(2)}
                            </Typography><br></br>
                            <span>{'\u00A0'}Porcentaje aplicado: {value.percent}%</span>
                          </React.Fragment>
                        }
                        secondary={
                          '\u00A0' +
                          "Fecha del corte: " + obtenerFechaConvertida(value.timestamp._seconds)
                        }
                      />

                    </ListItem>
                  </List>

                </>
              )
            })}

          </DialogContent>
        </Dialog>

      </div>
    </>
  );
}


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix={'\u20BF '}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
}

export default BalanceHistoryScreen;
