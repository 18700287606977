import {
  SAVE_STUDENT_RESET,
  SAVE_STUDENT_REQUEST,
  SAVE_STUDENT_SUCCESS,
  SAVE_STUDENT_FAIL,
  DELETE_STUDENT_RESET,
  DELETE_STUDENT_REQUEST,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_FAIL,
} from '../constants/studentConstants';

import {
  STUDENT_ACTIONS_URL
} from '../constants/urlConstants';

const urlBase = STUDENT_ACTIONS_URL;

const resetStudentSave = () => (dispatch) => {
  dispatch({ type: SAVE_STUDENT_RESET });
}

const saveStudent = (student) => async (dispatch, getState) => {

  try {
    dispatch({ type: SAVE_STUDENT_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    if (!student.id) {

      await fetch(
        urlBase +
        'user/student'
        , {
          method: 'POST',
          body: JSON.stringify({
            name: student.name,
            nickname: student.nickname,
            email: student.email,
            parentName: student.parentName,
            parentEmail: student.parentEmail,
            grade: student.grade
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userInfo.token
          }
        })
        .then(function (response) {
          if (response.ok) {
            ok = true;
          } else if (response.status === 400) {
            err400 = true;
          } else {
            // unknown = true;
          }
          return response.json();
        })
        .then(function (data) {
          // console.log('data => ', data);
          msg = data;
        })
        .catch(function (err) {
          console.error(err);
          // unknown = true;
        });

    } else {

      await fetch(
        urlBase +
        'user/student'
        , {
          method: 'PUT',
          body: JSON.stringify(student),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userInfo.token
          }
        })
        .then(function (response) {
          if (response.ok) {
            ok = true;
          } else if (response.status === 400) {
            err400 = true;
          } else {
            // unknown = true;
          }
          return response.json();
        })
        .then(function (data) {
          // console.log('data = ', data);
          msg = data;
        })
        .catch(function (err) {
          console.error(err);
          // unknown = true;
        });

    }

    if (ok) {
      dispatch({ type: SAVE_STUDENT_SUCCESS, payload: msg });
      if (!student.id) {
        // sendEmail(msg);
      }
    } else if (err400) {
      dispatch({ type: SAVE_STUDENT_FAIL, payload: msg.errors });
    } else {
      alert("Error desconocido, intenta más tarde");
      dispatch({ type: SAVE_STUDENT_FAIL, payload: [{ param: 'default', msg: "Ocurrió un error, intenta más tarde" }] });
    }

  } catch (error) {
    dispatch({ type: SAVE_STUDENT_FAIL, payload: [{ msg: error.message }] });
  }

};


const deleteStudent = (student) => async (dispatch, getState) => {

  try {
    dispatch({ type: SAVE_STUDENT_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    if (student.id) {

      await fetch(
        urlBase +
        'user/student'
        , {
          method: 'DELETE',
          body: JSON.stringify({
            id: student.id,
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userInfo.token
          }
        })
        .then(function (response) {
          if (response.ok) {
            ok = true;
          } else if (response.status === 400) {
            err400 = true;
          } else {
            // unknown = true;
          }
          return response.json();
        })
        .then(function (data) {
          // console.log('data => ', data);
          msg = data;
        })
        .catch(function (err) {
          console.error(err);
          // unknown = true;
        });

    }

    if (ok) {
      dispatch({ type: SAVE_STUDENT_SUCCESS, payload: msg });
    } else if (err400) {
      dispatch({ type: SAVE_STUDENT_FAIL, payload: msg.errors });
    } else {
      alert("Error desconocido, intenta más tarde");
      dispatch({ type: SAVE_STUDENT_FAIL, payload: [{ param: 'default', msg: "Ocurrió un error, intenta más tarde" }] });
    }

  } catch (error) {
    dispatch({ type: SAVE_STUDENT_FAIL, payload: [{ msg: error.message }] });
  }


};

function sendEmail(user) {
  // console.log(user);

  fetch(
      'https://mailingapp.azurewebsites.net/simulador/comments/nuevo'
      , {
          method: 'POST',
          body: JSON.stringify({
              name: user.name,
              email: user.email,
              user: user.nickname,
              password: user.password
          }),
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          }
      })
      .then(function (response) {
          return response.json();
      })
      .then(function (data) {
          // console.log('email = ', data);
      })
      .catch(function (err) {
          console.error(err);
          // unknown = true;
      });

}


export {
  resetStudentSave,
  saveStudent,
  deleteStudent
};
