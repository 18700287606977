import {
    GET_BUY_CREDIT_LIST_RESET,
    GET_BUY_CREDIT_LIST_REQUEST,
    GET_BUY_CREDIT_LIST_SUCCESS,
    GET_BUY_CREDIT_LIST_FAIL,

    UPDATE_BUY_CREDIT_RESET,
    UPDATE_BUY_CREDIT_REQUEST,
    UPDATE_BUY_CREDIT_SUCCESS,
    UPDATE_BUY_CREDIT_FAIL,

    GET_JUSTIFICATIONS_LIST_RESET,
    GET_JUSTIFICATIONS_LIST_REQUEST,
    GET_JUSTIFICATIONS_LIST_SUCCESS,
    GET_JUSTIFICATIONS_LIST_FAIL,

    SAVE_JUSTIFICATION_RESET,
    SAVE_JUSTIFICATION_REQUEST,
    SAVE_JUSTIFICATION_SUCCESS,
    SAVE_JUSTIFICATION_FAIL


} from '../constants/buyCreditConstants';


function justificationsListReducer(state = { justifications: [] }, action) {
    switch (action.type) {
        case GET_JUSTIFICATIONS_LIST_RESET:
            return { loading: false, justifications: [], success: false };
        case GET_JUSTIFICATIONS_LIST_REQUEST:
            return { loading: true, justifications: action.payload };
        case GET_JUSTIFICATIONS_LIST_SUCCESS:
            return { loading: false, justifications: action.payload, success: true };
        case GET_JUSTIFICATIONS_LIST_FAIL:
            return { loading: false, error: action.payload, justifications: [] };
        default:
            return state;
    }
}

function buyCreditListReducer(state = { users: [] }, action) {
    switch (action.type) {
        case GET_BUY_CREDIT_LIST_RESET:
            return { loading: false, users: [], success: false };
        case GET_BUY_CREDIT_LIST_REQUEST:
            return { loading: true, users: action.payload };
        case GET_BUY_CREDIT_LIST_SUCCESS:
            return { loading: false, users: action.payload, success: true };
        case GET_BUY_CREDIT_LIST_FAIL:
            return { loading: false, error: action.payload, users: [] };
        default:
            return state;
    }
}

function updateBuyCreditReducer(state = { user: [] }, action) {
    switch (action.type) {
        case UPDATE_BUY_CREDIT_RESET:
            return { loading: false, user: [], success: false };
        case UPDATE_BUY_CREDIT_REQUEST:
            return { loading: true, user: action.payload };
        case UPDATE_BUY_CREDIT_SUCCESS:
            return { loading: false, user: action.payload, success: true };
        case UPDATE_BUY_CREDIT_FAIL:
            return { loading: false, error: action.payload, users: [] };
        default:
            return state;
    }
}


function justificationSaveReducer(state = { justification: {} }, action) {
    switch (action.type) {
      case SAVE_JUSTIFICATION_RESET:
        return { loading: false, success: false };
      case SAVE_JUSTIFICATION_REQUEST:
        return { loading: true };
      case SAVE_JUSTIFICATION_SUCCESS:
        return { loading: false, success: true, justification: action.payload };
      case SAVE_JUSTIFICATION_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }


export {
    justificationsListReducer,
    buyCreditListReducer,
    updateBuyCreditReducer,
    justificationSaveReducer
};
