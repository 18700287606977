import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import {
    getSavingsConfApi,
    updateSavingsConfApi,
    resetSavingsConfApi
} from '../actions/savingsConfActions'


// MATERIAL UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiPhoneNumber from 'material-ui-phone-number';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';


import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';


function SavingsConfScreen(props) {


    const getSavingsConf = useSelector(state => state.getSavingsConf);
    const { loading: loadingGet, success: successGet, conf: confGet } = getSavingsConf;

    const saveSavingsConf = useSelector(state => state.saveSavingsConf);
    const { loading: loadingSave, success: successSave, conf: confSave } = saveSavingsConf;



    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const [cutoffHour, setCutoffHour] = useState('00:00');

    const [daysOfWeekId, setDaysOfWeekId] = useState(0);
    const [daysOfWeekIdError, setDaysOfWeekIdError] = useState('');


    const [profit, setProfit] = useState(0);
    const [profitError, setProfitError] = useState('');


    const [daysOfWeek] = useState([
        { id: 0, name: "Domingo" },
        { id: 1, name: "Lunes" },
        { id: 2, name: "Martes" },
        { id: 3, name: "Miércoles" },
        { id: 4, name: "Jueves" },
        { id: 5, name: "Viernes" },
        { id: 6, name: "Sábado" },
    ]);


    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: '#f5f5f5',
            minHeight: 'calc(100vh - 64px)',
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'right',
            color: theme.palette.text.secondary,
        },
        selectText: {
            textAlign: 'left',
        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,

        },
        margin: {
            margin: theme.spacing(2),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        errorColor: {
            color: '#f44336',
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        dispatch(getSavingsConfApi());
        return () => {
            dispatch(resetSavingsConfApi());
        };
    }, []);

    useEffect(() => {
        if (successGet) {
            setDaysOfWeekId(confGet.cutoffDay);
            setCutoffHour(confGet.cutoffHour);
            setProfit(confGet.cutoffPercent);
        }
        return () => {

        };
    }, [getSavingsConf]);

    useEffect(() => {

        if (successSave) {
            setOpen(true);
        }

        return () => {

        };
    }, [saveSavingsConf]);


    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        let daySelected = event.target.value;
        if (daySelected > -1) {
            setDaysOfWeekIdError('');
        } else {
            setDaysOfWeekIdError('Debe seleccionar un día para realizar el corte');
        }
        setDaysOfWeekId(daySelected);
    };

    const checkProfit = (v) => {
        if (!v || v.trim().length == 0) {
            setProfitError('Este campo no debe estar vacío');
        } else if (v < 0 || v > 9999) {
            setProfitError('Debe establecer un valor entre 0 y 9999');
        }
        else {
            setProfitError("");
        }
        setProfit(v)
    }



    const checkCutoffHour = (v) => {
        setCutoffHour(v)
    }


    const executeScroll = () => {
        // if (name && email) {
        dispatch(updateSavingsConfApi({ cutoffDay: daysOfWeekId, cutoffHour: cutoffHour, cutoffPercent: profit }));
        // }
    };



    return (
        <>
            <div className={classes.root}>
                <h1>Configuración para el corte semanal</h1>
                <h4>(Todos los cortes son procesados a la 1 am)</h4>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <Paper className={classes.paper}>
                            {/* <form className={classes.form} noValidate > */}

                            <br></br>

                            <FormControl fullWidth
                                variant="outlined" >

                                <InputLabel id="demo-simple-select-outlined-label-ldo">Día de corte</InputLabel>
                                <Select
                                    label="Día de corte"
                                    labelId="demo-simple-select-outlined-label-select-day"
                                    id="demo-simple-select-outlined-select-day"
                                    value={daysOfWeekId}
                                    fullWidth
                                    variant="outlined"
                                    className={classes.selectText}
                                    onChange={handleChange}
                                    error={daysOfWeekIdError.length > 0}
                                    helperText={daysOfWeekIdError && daysOfWeekIdError}
                                >
                                    <MenuItem value="-1">
                                        Día de corte:
                                    </MenuItem>
                                    {daysOfWeek && daysOfWeek.map((value, index) => {
                                        return (
                                            <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText className={classes.errorColor}>{daysOfWeekIdError && daysOfWeekIdError ? daysOfWeekIdError : ''}</FormHelperText>

                            </FormControl>

                            <br></br>
                            <br></br>

                            {/* <TextField
                                id="time"
                                disabled
                                label="Hora de corte"
                                type="time"
                                variant="outlined"
                                value={cutoffHour}
                                onChange={(e) => checkCutoffHour(e.target.value)}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 3600, // 5 min
                                }}
                            /> */}


                            <TextField
                                value={profit}
                                error={profitError.length > 0}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                type="number"
                                id="profit"
                                label="Interés (%)"
                                name="profit"
                                autoComplete="profit"
                                onChange={(e) => checkProfit(e.target.value)}
                                helperText={profitError && profitError}
                            />

                            <br></br>
                            <br></br>

                            <Button
                                variant="contained"
                                color="primary"
                                disabled={daysOfWeekIdError.length > 0 || profitError.length > 0}
                                onClick={executeScroll}
                                // startIcon={<CheckIcon />}
                                className={classes.margin}>
                                Modificar
                                </Button>
                            {/* </form> */}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Backdrop className={classes.backdrop} open={loadingGet || loadingSave}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        La información ha sido almacenada de forma correcta.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}





export default SavingsConfScreen;
