import {
    GET_TEACHER_GROUP_LIST_RESET,
    GET_TEACHER_GROUP_LIST_REQUEST,
    GET_TEACHER_GROUP_LIST_SUCCESS,
    GET_TEACHER_GROUP_LIST_FAIL,
    UPDATE_TEACHER_GROUP_LIST_RESET,
    UPDATE_TEACHER_GROUP_LIST_REQUEST,
    UPDATE_TEACHER_GROUP_LIST_SUCCESS,
    UPDATE_TEACHER_GROUP_LIST_FAIL,
    SAVE_TEACHER_RESET,
    SAVE_TEACHER_REQUEST,
    SAVE_TEACHER_SUCCESS,
    SAVE_TEACHER_FAIL,

} from '../constants/teacherGroupConstants';

function GetTeacherGroupListReducer(state = { list: { groups: [], teachers: [] } }, action) {
    switch (action.type) {
        case GET_TEACHER_GROUP_LIST_RESET:
            return { loading: false, success: false, list: { groups: [], teachers: [] } };
        case GET_TEACHER_GROUP_LIST_REQUEST:
            return { loading: true, list: [] };
        case GET_TEACHER_GROUP_LIST_SUCCESS:
            return { loading: false, list: action.payload, success: true };
        case GET_TEACHER_GROUP_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}


function UpdateTeacherGroupListReducer(state = { list: { groups: [], teachers: [] } }, action) {
    switch (action.type) {
        case UPDATE_TEACHER_GROUP_LIST_RESET:
            return { loading: false, success: false, list: { groups: [], teachers: [] } };
        case UPDATE_TEACHER_GROUP_LIST_REQUEST:
            return { loading: true, list: [] };
        case UPDATE_TEACHER_GROUP_LIST_SUCCESS:
            return { loading: false, list: action.payload, success: true };
        case UPDATE_TEACHER_GROUP_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

function TeacherSaveReducer(state = { teacher: {} }, action) {
    switch (action.type) {
        case SAVE_TEACHER_RESET:
            return { loading: false, success: false };
        case SAVE_TEACHER_REQUEST:
            return { loading: true, teacher: [] };
        case SAVE_TEACHER_SUCCESS:
            return { loading: false, teacher: action.payload, success: true };
        case SAVE_TEACHER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export {
    GetTeacherGroupListReducer,
    UpdateTeacherGroupListReducer,
    TeacherSaveReducer
};