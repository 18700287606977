import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING,
  CART_SAVE_PAYMENT,

  GET_CHECK_BALANCE_RESET,
  GET_CHECK_BALANCE_REQUEST,
  GET_CHECK_BALANCE_SUCCESS,
  GET_CHECK_BALANCE_FAIL,


  TRANSACTION_OK,
  TRANSACTION_RESET,

  GET_ORDER_USER_RESET,
  GET_ORDER_USER_REQUEST,
  GET_ORDER_USER_SUCCESS,
  GET_ORDER_USER_FAIL,

} from "../constants/cartConstants";

function cartReducer(state = { cartItems: [], shipping: {}, payment: {} }, action) {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload;
      const product = state.cartItems.find(x => x.product === item.product);
      if (product) {
        return {
          cartItems:
            state.cartItems.map(x => x.product === product.product ? item : x)
        };
      }
      return { cartItems: [...state.cartItems, item] };
    case CART_REMOVE_ITEM:
      return { cartItems: state.cartItems.filter(x => x.product !== action.payload) };
    case CART_SAVE_SHIPPING:
      return { ...state, shipping: action.payload };
    case CART_SAVE_PAYMENT:
      return { ...state, payment: action.payload };
    default:
      return state
  }
}

function checkBalanceReducer(state = { status: {} }, action) {
  switch (action.type) {
    case GET_CHECK_BALANCE_RESET:
      return { loading: false, success: false, status: {} };
    case GET_CHECK_BALANCE_REQUEST:
      return { loading: true };
    case GET_CHECK_BALANCE_SUCCESS:
      return { loading: false, success: true, status: action.payload };
    case GET_CHECK_BALANCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function buyProductDoneReducer(state = { status: {} }, action) {
  switch (action.type) {
    case TRANSACTION_RESET:
      return { loading: false, success: false, status: {} };
    case TRANSACTION_OK:
      return { loading: false, success: true, status: {} };
    default:
      return state;
  }
}


function userOrdersReducer(state = { items: [] }, action) {
  switch (action.type) {
    case GET_ORDER_USER_RESET:
      return { loading: false, success: false, items: [] };
    case GET_ORDER_USER_REQUEST:
      return { loading: true };
    case GET_ORDER_USER_SUCCESS:
      return { loading: false, success: true, items: action.payload };
    case GET_ORDER_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}


export { cartReducer, checkBalanceReducer, buyProductDoneReducer, userOrdersReducer }