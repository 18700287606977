import React from 'react';
import Carousel from "react-material-ui-carousel"
import { makeStyles } from '@material-ui/core/styles';
import autoBind from "auto-bind"
import '../style//BannerPartners.scss';
import Link from '@material-ui/core/Link';

import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Grid,
} from '@material-ui/core';

function Banner(props) {
    if (props.newProp) console.log(props.newProp)
    const contentPosition = props.contentPosition ? props.contentPosition : "left"
    const totalItems = props.length ? props.length : 3;
    // const mediaLength = totalItems - 1;
    const mediaLength = totalItems;

    const useStyles = makeStyles({
        root: {
            maxWidth: 345,
        },
        mediaComp: {
            height: 110,
        },
    });

    const classes = useStyles();
    const preventDefault = (event) => event.preventDefault();

    let items = [];
    const content = (
        <Grid item xs={12 / totalItems} key="content" className={classes.mediaComp} >
            <CardContent className="Content" >
                <Typography className="Title">
                    {props.item.Name}
                </Typography>
                <Typography className="Caption">
                    {props.item.Caption}
                </Typography>
            </CardContent>
        </Grid>
    )


    for (let i = 0; i < mediaLength; i++) {
        const item = props.item.Items[i];

        const media = (
            <Grid item xs={12 / totalItems} key={item.Name}  >
                <Link href="https://www.google.com.mx" target="_blank"  >
                    <CardMedia
                        style={{ display: 'flex', justifyContent: 'center' }}
                        className={classes.mediaComp}
                        title={item.Name}
                    >
                    <img src={item.Image} height="120" />
                    </CardMedia>
                </Link>
            </Grid>
        )
        items.push(media);
    }

    // if (contentPosition === "left") {
    //     items.unshift(content);
    // } else if (contentPosition === "right") {
    //     items.push(content);
    // } else if (contentPosition === "middle") {
    //     items.splice(items.length / 2, 0, content);
    // }

    return (
        <Card raised className="Banner">
            <Grid container spacing={0} className="BannerGrid">
                {items}
            </Grid>
        </Card>
    )
}

const items = [
    {
        Name: "Uno",
        Caption: "",
        contentPosition: "left",
        Items: [
            {
                Name: "p1",
                Image: "https://osmarperez.com/imag/16.jpg"
            },
            {
                Name: "p2",
                Image: "https://osmarperez.com/imag/17.jpg"
            },
            {
                Name: "p3",
                Image: "https://osmarperez.com/imag/18.jpg"
            },
            {
                Name: "p4",
                Image: "https://osmarperez.com/imag/18.jpg"
            },
            {
                Name: "p5",
                Image: "https://osmarperez.com/imag/18.jpg"
            },
            {
                Name: "p6",
                Image: "https://osmarperez.com/imag/18.jpg"
            }
        ]
    },
    {
        Name: "Dos",
        Caption: "",
        contentPosition: "middle",
        Items: [
            {
                Name: "p7",
                Image: "https://osmarperez.com/imag/19.jpg"
            },
            {
                Name: "p8",
                Image: "https://osmarperez.com/imag/20.jpg"
            },
            {
                Name: "p9",
                Image: "https://osmarperez.com/imag/17.jpg"
            },
            {
                Name: "p10",
                Image: "https://osmarperez.com/imag/17.jpg"
            },
            {
                Name: "p11",
                Image: "https://osmarperez.com/imag/17.jpg"
            },
            {
                Name: "p12",
                Image: "https://osmarperez.com/imag/17.jpg"
            },
            {
                Name: "p13",
                Image: "https://osmarperez.com/imag/17.jpg"
            }
        ]
    },
    {
        Name: "Tres",
        Caption: "",
        contentPosition: "right",
        Items: [
            {
                Name: "p14",
                Image: "https://osmarperez.com/imag/19.jpg"
            },
            {
                Name: "p15",
                Image: "https://osmarperez.com/imag/16.jpg"
            },
            {
                Name: "p16",
                Image: "https://osmarperez.com/imag/18.jpg"
            },
            {
                Name: "p17",
                Image: "https://osmarperez.com/imag/18.jpg"
            },
            {
                Name: "p18",
                Image: "https://osmarperez.com/imag/18.jpg"
            },
            {
                Name: "p19",
                Image: "https://osmarperez.com/imag/18.jpg"
            },
            {
                Name: "p20",
                Image: "https://osmarperez.com/imag/18.jpg"
            },
            {
                Name: "p21",
                Image: "https://osmarperez.com/imag/18.jpg"
            }
        ]
    }
]

class BannerExample extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            autoPlay: true,
            timer: 5500,
            animation: "fade",
            indicators: false,
            timeout: 500,
            navButtonsAlwaysVisible: true,
            navButtonsAlwaysInvisible: false,
            totalItems: props.totalItems ? props.totalItems : 4
        }

        autoBind(this);
    }

    toggleAutoPlay() {
        this.setState({
            autoPlay: !this.state.autoPlay
        })
    }

    toggleIndicators() {
        this.setState({
            indicators: !this.state.indicators
        })
    }

    toggleNavButtonsAlwaysVisible() {
        this.setState({
            navButtonsAlwaysVisible: !this.state.navButtonsAlwaysVisible
        })
    }

    toggleNavButtonsAlwaysInvisible() {
        this.setState({
            navButtonsAlwaysInvisible: !this.state.navButtonsAlwaysInvisible
        })
    }

    changeAnimation(event) {
        this.setState({
            animation: event.target.value
        })
    }

    changeTimeout(event, value) {
        this.setState({
            timeout: value
        })
    }

    render() {
        return (
            <div style={{ marginTop: "30px", color: "#494949", height: 50 }}>

                <Carousel
                    className="Example"
                    autoPlay={this.state.autoPlay}
                    timer={this.state.timer}
                    animation={this.state.animation}
                    indicators={this.state.indicators}
                    timeout={this.state.timeout}
                    navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
                    navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
                >
                    {
                        items.map((item, index) => {
                            return <Banner item={item} key={index} length={this.state.totalItems} />
                        })
                    }
                </Carousel>

            </div>

        )
    }
}

export default BannerExample;