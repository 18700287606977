import Axios from "axios";
import Cookie from "js-cookie";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING,
  CART_SAVE_PAYMENT,

  GET_CHECK_BALANCE_RESET,
  GET_CHECK_BALANCE_REQUEST,
  GET_CHECK_BALANCE_SUCCESS,
  GET_CHECK_BALANCE_FAIL,

  TRANSACTION_OK,
  TRANSACTION_RESET

} from "../constants/cartConstants";

import {
  GROUP_ACTIONS_URL,
  IMAGES_URL
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;
const imageUrl = IMAGES_URL;

const addToCart = (product, qty) => async (dispatch, getState) => {



  // try {
  // const { data } = await Axios.get("/api/products/" + productId);
  let imgTmp = product.images.length > 0 ? imageUrl + product.images[0].url : "https://aeasa.com.mx/wp-content/uploads/2020/02/SIN-IMAGEN.jpg";

  dispatch({
    type: CART_ADD_ITEM, payload: {
      product: product.id,
      name: product.title,
      image: imgTmp,
      price: convertToDecimal(product.price),
      countInStock: convertToDecimal(product.stock),
      qty: convertToDecimal(qty)
    }
  });
  const { cart: { cartItems } } = getState();
  Cookie.set("cartItems", JSON.stringify(cartItems));

  // } catch (error) {
  // }
}


const addToCartFrom = (product, qty) => async (dispatch, getState) => {


  // try {
  // const { data } = await Axios.get("/api/products/" + productId);
  if (qty > 0) {
    dispatch({
      type: CART_ADD_ITEM, payload: {
        product: product.product,
        name: product.name,
        image: product.image,
        price: convertToDecimal(product.price),
        countInStock: convertToDecimal(product.countInStock),
        qty: convertToDecimal(qty)
      }
    });
    const { cart: { cartItems } } = getState();
    Cookie.set("cartItems", JSON.stringify(cartItems));
  }
  // } catch (error) {
  // }
}

const roughScale = (x, base) => {
  let parsed = Number.parseInt(x, base);
  if (Number.isNaN(parsed)) {
    return 0;
  }
  return parsed;
}

const removeFromCart = (productId) => (dispatch, getState) => {
  dispatch({ type: CART_REMOVE_ITEM, payload: productId });

  const { cart: { cartItems } } = getState();
  Cookie.set("cartItems", JSON.stringify(cartItems));
}


const removeAllProductsFromCart = () => (dispatch, getState) => {
  const { cart: { cartItems } } = getState();
  cartItems.forEach(function (product) {
    dispatch(removeFromCart(product.product));
  });
}

const buyProductDone = () => (dispatch) => {
  dispatch({ type: TRANSACTION_OK });
}


const buyProductReset = () => (dispatch) => {
  dispatch({ type: TRANSACTION_RESET });
}


const saveShipping = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_SHIPPING, payload: data });
}


const savePayment = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_PAYMENT, payload: data });
}

const resetCheckBalanceApi = () => (dispatch) => {
  dispatch({ type: GET_CHECK_BALANCE_RESET });
}


const checkBalanceApi = (products, total, verifyPercent) => async (dispatch, getState) => {

  dispatch({ type: GET_CHECK_BALANCE_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/checkbalance'
    , {
      method: 'POST',
      body: JSON.stringify({
        products,
        total,
        id: userInfo._id,
        verifyPercent: verifyPercent
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: GET_CHECK_BALANCE_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: GET_CHECK_BALANCE_FAIL, payload: msg.errors });
  } else {
    alert("Ocurrió un error, intenta más tarde");
    dispatch({ type: GET_CHECK_BALANCE_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}

function convertToDecimal(r) {
  if (Number.isNaN(Number.parseFloat(r))) {
    return 0;
  }
  return parseFloat(r);
}

export {
  addToCart,
  removeFromCart,
  saveShipping,
  savePayment,
  addToCartFrom,
  checkBalanceApi,
  resetCheckBalanceApi,
  removeAllProductsFromCart,
  buyProductDone,
  buyProductReset
}