export const GET_GROUP_PURCHASES_RESET = 'GET_GROUP_PURCHASES_RESET';
export const GET_GROUP_PURCHASES_REQUEST = 'GET_GROUP_PURCHASES_REQUEST';
export const GET_GROUP_PURCHASES_SUCCESS = 'GET_GROUP_PURCHASES_SUCCESS';
export const GET_GROUP_PURCHASES_FAIL = 'GET_GROUP_PURCHASES_FAIL';

export const GET_STUDENT_GROUP_PURCHASES_RESET = 'GET_STUDENT_GROUP_PURCHASES_RESET';
export const GET_STUDENT_GROUP_PURCHASES_REQUEST = 'GET_STUDENT_GROUP_PURCHASES_REQUEST';
export const GET_STUDENT_GROUP_PURCHASES_SUCCESS = 'GET_STUDENT_GROUP_PURCHASES_SUCCESS';
export const GET_STUDENT_GROUP_PURCHASES_FAIL = 'GET_STUDENT_GROUP_PURCHASES_FAIL';

export const GET_STUDENT_WALLET_BALANCE_RESET = 'GET_STUDENT_WALLET_BALANCE_RESET';
export const GET_STUDENT_WALLET_BALANCE_REQUEST = 'GET_STUDENT_WALLET_BALANCE_REQUEST';
export const GET_STUDENT_WALLET_BALANCE_SUCCESS = 'GET_STUDENT_WALLET_BALANCE_SUCCESS';
export const GET_STUDENT_WALLET_BALANCE_FAIL = 'GET_STUDENT_WALLET_BALANCE_FAIL';

export const GET_ASSIGNMENT_BY_AREA_RESET = 'GET_ASSIGNMENT_BY_AREA_RESET';
export const GET_ASSIGNMENT_BY_AREA_REQUEST = 'GET_ASSIGNMENT_BY_AREA_REQUEST';
export const GET_ASSIGNMENT_BY_AREA_SUCCESS = 'GET_ASSIGNMENT_BY_AREA_SUCCESS';
export const GET_ASSIGNMENT_BY_AREA_FAIL = 'GET_ASSIGNMENT_BY_AREA_FAIL';
