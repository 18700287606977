import {
    DATA_CONTACT_GET_REQUEST,
    DATA_CONTACT_GET_SUCCESS,
    DATA_CONTACT_GET_FAIL,
    DATA_CONTACT_SAVE_REQUEST,
    DATA_CONTACT_SAVE_SUCCESS,
    DATA_CONTACT_SAVE_FAIL,
    DATA_CONTACT_RESET_SUCCESS,
    DATA_CONTACT_POST_REQUEST,
    DATA_CONTACT_POST_SUCCESS,
    DATA_CONTACT_POST_FAIL,
    DATA_CONTACT_POST_RESET,
} from '../constants/dataContactConstants';

function getDataContactReducer(state = { contact: {} }, action) {
    switch (action.type) {
        case DATA_CONTACT_GET_REQUEST:
            return { loading: true, contact: {} };
        case DATA_CONTACT_GET_SUCCESS:
            return { loading: false, contact: action.payload, success: true };
        case DATA_CONTACT_GET_FAIL:
            return { loading: false, contact: action.payload };
        default:
            return state;
    }
}

function saveDataContactReducer(state = { contactsd: {} }, action) {
    switch (action.type) {
        case DATA_CONTACT_RESET_SUCCESS:
            return { loading: false, contactsd: {}, success: false };
        case DATA_CONTACT_SAVE_REQUEST:
            return { loading: true, contactsd: {} };
        case DATA_CONTACT_SAVE_SUCCESS:
            return { loading: false, contactsd: action.payload, success: true };
        case DATA_CONTACT_SAVE_FAIL:
            return { loading: false, contactsd: action.payload };
        default:
            return state;
    }
}


function postCommentsReducer(state = { message: {} }, action) {
    switch (action.type) {
        case DATA_CONTACT_POST_RESET:
            return { loading: false, message: {}, success: false };
        case DATA_CONTACT_POST_REQUEST:
            return { loading: true, message: {} };
        case DATA_CONTACT_POST_SUCCESS:
            return { loading: false, message: action.payload, success: true };
        case DATA_CONTACT_POST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export {
    getDataContactReducer,
    saveDataContactReducer,
    postCommentsReducer
};
