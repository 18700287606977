import {

    GET_SAVINGS_CONF_RESET,
    GET_SAVINGS_CONF_REQUEST,
    GET_SAVINGS_CONF_SUCCESS,
    GET_SAVINGS_CONF_FAIL,

    UPDATE_SAVINGS_CONF_RESET,
    UPDATE_SAVINGS_CONF_REQUEST,
    UPDATE_SAVINGS_CONF_SUCCESS,
    UPDATE_SAVINGS_CONF_FAIL,

} from '../constants/savingsConfConstants';

import {
    GROUP_ACTIONS_URL
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;

const getSavingsConfApi = () => async (dispatch, getState) => {

    dispatch({ type: GET_SAVINGS_CONF_REQUEST });
    const { userSignin: { userInfo } } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/savingsconf/'
        , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: GET_SAVINGS_CONF_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: GET_SAVINGS_CONF_FAIL, payload: msg.errors });
    } else {
        dispatch({ type: GET_SAVINGS_CONF_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }

};


const updateSavingsConfApi = (newConf) => async (dispatch, getState) => {


    dispatch({ type: UPDATE_SAVINGS_CONF_REQUEST });
    const { userSignin: { userInfo } } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/savingsconf/'
        , {
            method: 'PUT',
            body: JSON.stringify(newConf),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: UPDATE_SAVINGS_CONF_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: UPDATE_SAVINGS_CONF_FAIL, payload: msg.errors });
    } else {
        dispatch({ type: UPDATE_SAVINGS_CONF_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }
}

const resetSavingsConfApi = () => (dispatch) => {
    dispatch({ type: GET_SAVINGS_CONF_RESET });
    dispatch({ type: UPDATE_SAVINGS_CONF_RESET });
}

export {
    getSavingsConfApi,
    updateSavingsConfApi,
    resetSavingsConfApi
};
