import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TeacherComponent from '../components/TeacherComponent';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiAlert from '@material-ui/lab/Alert';

import {
    resetTeacherGroupListApi,
    getTeacherGroupListApi,
    updateTeacherGroupListApi,
    resetTeacherGroupUpdateApi
} from '../actions/teacherGroupActions';
import { TrainRounded, TrendingUpTwoTone } from '@material-ui/icons';

function TeacherGroupScreen(props) {

    const [state] = useState({
        vertical: 'bottom',
        horizontal: 'center',
    });

    const { vertical, horizontal } = state;

    const getTeacherGroupList = useSelector(state => state.getTeacherGroupList);
    const { loading: loadingList, list, error: errorList } = getTeacherGroupList;


    const updateTeacherGroupList = useSelector(state => state.updateTeacherGroupList);
    const { loading: loadingUpdate, listUpdate, error: errorUpdate, success: successUpdate } = updateTeacherGroupList;

    const [doUserChange, setDoUserChange] = useState(false);


    const [disabledSelect, setDisabledSelect] = useState(true);
    const [teacherChecked, setTeacherChecked] = useState([0]);
    const [checkedGroup, setCheckedGroup] = useState([0]);
    const [showBackdrop, setShowBackdrop] = useState(true);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showModal, setShowModal] = useState(false);


    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [nickname, setNickname] = useState('');
    const [grade, setGrade] = useState('');

    const [operationType, setOperationType] = useState(0);
    const [principalLegend, setPrincipalLegend] = useState('Nuevo Profesor');


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTeacherGroupListApi());
        return () => {
            //
            dispatch(resetTeacherGroupListApi());
            dispatch(resetTeacherGroupUpdateApi());
        };
    }, []);



    useEffect(() => {
        if (loadingList) {
            setShowBackdrop(true);
        } else {
            setShowBackdrop(false);
        }
        return () => {
            //
        };
    }, [getTeacherGroupList]);


    useEffect(() => {
        if (loadingUpdate) {
            setShowBackdrop(true);
        } else {
            setShowBackdrop(false);
        }

        if (successUpdate) {
            setTeacherChecked([0]);
            setCheckedGroup([0]);
            setShowSnackbar(true);
            dispatch(getTeacherGroupListApi());
            setDisabledSelect(true);
        }

        return () => {
            //
        };
    }, [updateTeacherGroupList]);


    // Configuración de estilos
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: '#f5f5f5',
            minHeight: 'calc(100vh - 64px)',
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'right',
            color: theme.palette.text.secondary,
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 400,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        noSelected: {
            color: 'red',
        },
        selected: {
            color: 'green'
        },
        margin: {
            backgroundColor: 'white',
            marginBottom: theme.spacing(2),
        },
        cancel: {
            margin: theme.spacing(2),
            textAlign: 'left',
        },
        rightAlng: {
            textAlign: 'left',
        }
    }));

    const classes = useStyles();



    const handleSelectTeacher = (id) => () => {


        setDoUserChange(false);

        const currentIndex = teacherChecked.indexOf(id);
        let newChecked = [...teacherChecked]; // Se crea el nuevo array con los datos que y existen

        if (currentIndex === -1) {
            newChecked = [0];
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setTeacherChecked(newChecked);
        setCheckedGroup([0]);
        iterateGroupsArry(newChecked);
        toogleButtonAsign(newChecked);
    };

    const iterateGroupsArry = (newChecked) => {
        let newGroups = [];
        for (var student of newChecked) {
            if (student !== 0) {
                // Ya tenemos el ID ahora buscamos la propiedad que contenga los grupos
                let obj = list.teachers.find(o => o.id === student);
                if (obj.hasOwnProperty('groups')) {
                    if (obj.groups.length > 0) {
                        for (var group of obj.groups) {
                            if (!newGroups.includes(group)) {
                                newGroups.push(group);
                            }
                        }
                    }
                } else {
                    // alert("no definido");
                }
            }
        }
        setCheckedGroup(newGroups);
    }

    const toogleButtonAsign = (newChecked) => {

        if (newChecked.length > 0) {
            if (newChecked.length === 1) {
                let index = newChecked.indexOf(0);
                if (index > -1) {
                    setDisabledSelect(true);
                } else {
                    setDisabledSelect(false);
                }
            } else {
                setDisabledSelect(false);
            }
        }
    }

    const handleDoassign = () => {
        // e.preventDefault();

        // if(checkedGroup.length > 0){

        //     // Comprobar si el grupo ya está asignado otro profesor 
        //     if (teacherChecked) {


        //         const nuevaEdicion = list.teachers.find(o => o.id === studentId);
        //         passUserDataToGroupProps(nuevaEdicion);
        //     } else {
        //         passUserDataToGroupProps();
        //     }


        // } else {

        //     alert("No tiene elementos");

        // }
        // console.log("checkedGroup"); 
        // console.log(checkedGroup); 


        if (doUserChange) {
            dispatch(updateTeacherGroupListApi(teacherChecked, checkedGroup))
        }



    };


    const handleSelectGroup = (id) => () => {

        setDoUserChange(true);

        const currentIndex = checkedGroup.indexOf(id);
        const newChecked = [...checkedGroup];
        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setCheckedGroup(newChecked);
    };


    // Cerrar el snackbar
    const handlerCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false);
    };

    const passUserDataToGroupProps = (data) => {
        if (data) {
            setId(data.id);
            setName(data.name);
            setNickname(data.nickname);
            setEmail(data.email);
            setGrade(data.grade);
        } else {
            setId('');
            setName('');
            setNickname('');
            setEmail('');
            setGrade('');
        }
    };

    const handleOpenModal = (studentId, type) => () => {

        // 0 Nuevo profesor
        // 1 Actualización profesor
        // 2 Eliminación profesor

        if (studentId) {
            const nuevaEdicion = list.teachers.find(o => o.id === studentId);
            passUserDataToGroupProps(nuevaEdicion);
        } else {
            passUserDataToGroupProps();
        }

        switch (type) {
            // Nuevo profesor
            case 0:
                setOperationType(0);
                setPrincipalLegend("Nuevo Profesor");
                break;
            // Actualización profesor
            case 1:
                setOperationType(1);
                setPrincipalLegend("Actualizar Profesor");
                break;
            // Eliminación profesor
            case 2:
                setOperationType(2);
                setPrincipalLegend("Eliminar Profesor");
                break;
            default:
        }

        setShowModal(true);

    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const onchangeteacher = (data, type) => {

        setShowModal(false);

        if (type === 2) {
            dispatch(getTeacherGroupListApi());
            // dispatch(listGroups());
            // setOpenSnackbar(true);
            // setShowSnackbar(false);
            setShowSnackbar(true);
        }
    }

    return (
        <>

            <div className={classes.root}>

                <h1>Asignación de Profesor a Grupo(s)</h1>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={handleOpenModal(null, 0)}
                    startIcon={<PersonAddIcon />}
                    className={classes.margin}>
                    Agregar Profesor
            </Button>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={5}>

                        <Paper className={classes.paper}>

                            {loadingList ? (
                                <div>Obteniendo profesores...</div>
                            ) : errorList ? (
                                <div>Ocurrió un error</div>
                            ) : (
                                        <>
                                            <h3>Profesores</h3>
                                            <Divider />
                                            < List className={classes.list}>
                                                {list.teachers.map((value, index) => {
                                                    const labelId = `checkbox-list-label-${value.id}`;

                                                    return (

                                                        <ListItem key={value.id} role={undefined} dense button onClick={handleSelectTeacher(value.id)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={teacherChecked.indexOf(value.id) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText className={value.groups.length > 0 ? classes.selected : classes.noSelected} id={labelId} primary={`${value.name} - (${value.groups.length} grupo${value.groups.length > 1 ? 's' : ''})`} />
                                                            <ListItemSecondaryAction >
                                                                <IconButton edge="end" onClick={handleOpenModal(value.id, 1)} aria-label="comments">
                                                                    <CreateIcon />
                                                                </IconButton>
                                                                <IconButton edge="end" onClick={handleOpenModal(value.id, 2)} aria-label="comments">
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                            {/* <Divider /> */}

                                            <Divider />
                                            <h4 className={classes.rightAlng}>Total: {list.teachers.length} Profesores</h4>
                                        </>
                                    )}
                        </Paper>
                    </Grid>
                    <Grid item sm={12} md={2}>
                        <Paper className={classes.paper}>

                            <Grid
                                container
                                spacing={0}
                                alignItems="center"
                                justify="center"
                                style={{ minHeight: "220px" }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={disabledSelect}
                                    className={classes.button}
                                    endIcon={<Icon>arrow_forward</Icon>}
                                    onClick={handleDoassign}
                                >
                                    Asignar
                            </Button>
                            </Grid>

                        </Paper>
                    </Grid>
                    <Grid item sm={12} md={5}>
                        <Paper className={classes.paper}>


                            {loadingList ? (
                                <div>Obteniendo Grupos...</div>
                            ) : errorList ? (
                                <div>Ocurrió un error</div>
                            ) : (
                                        <>
                                            <h3>Grupos</h3>
                                            <Divider />
                                            < List className={classes.list}>
                                                {list.groups.map((value, index) => {
                                                    const labelId = `checkbox-list-label-${value.id}`;

                                                    return (
                                                        // <ListItem key={value.id} role={undefined} dense button onClick={handleGroupToogle(value.id)}>
                                                        <ListItem key={value.id} disabled={disabledSelect} role={undefined} dense button onClick={handleSelectGroup(value.id)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={checkedGroup.indexOf(value.id) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple

                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={labelId} primary={`${value.name}`} />
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                            <Divider />
                                            <h4 className={classes.rightAlng}>Total: {list.groups.length} Grupos</h4>
                                        </>
                                    )}
                        </Paper>
                    </Grid>
                </Grid>

            </div>

            <Backdrop className={classes.backdrop} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={handlerCloseSnackbar}
                // message="Se han realizado los cambios solicitados"
                key={vertical + horizontal}>
                <Alert onClose={handlerCloseSnackbar} severity="success">
                    Se han realizado los cambios solicitados
                    </Alert>
            </Snackbar>


            <Dialog
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{principalLegend}</DialogTitle>
                <DialogContent>

                    <TeacherComponent
                        type={operationType}
                        onchange={(e, t) => { onchangeteacher(e, t) }}
                        id={id}
                        name={name}
                        nickname={nickname}
                        email={email}
                        grade={grade}
                    />

                </DialogContent>
            </Dialog>

        </>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default TeacherGroupScreen;
