import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BarChart, Bar, LineChart, Line, YAxis, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import NumberFormat from 'react-number-format';

import {
    getAssignmentsByAreaApi
} from '../actions/studentWalletBalanceActions'

import { makeStyles, withStyles } from '@material-ui/core/styles';
// MATERIAL UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

function InsigniasAdminScreen(props) {

    const getAssignmentsByArea = useSelector(state => state.getAssignmentsByArea);
    const { loading: loadingList, error: errorList, success: successList, data } = getAssignmentsByArea;

    const [general, setGeneral] = useState([]);
    const [specific, setSpecific] = useState([]);

    const [specificTotal, setSpecificTotal] = useState(0);
    const [generalTotal, setGeneralTotal] = useState(0);

    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: '#f5f5f5',
            minHeight: 'calc(100vh - 64px)',
        },
        paper: {
            padding: theme.spacing(2),
            // textAlign: 'right',
            color: theme.palette.text.secondary,
            minHeight: 'calc(100vh - 192px)',
            // backgroundColor: 'red',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        width100: {
            width: '500px'
        },
        customTooltip: {
            backgroundColor: '#f5f5f5',
            padding: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            border: '1px solid #9fc33b'
        },
        blueColorFont: {
            color: '#9fc33b'
        }
    }));

    const classes = useStyles();

    useEffect(() => {
        // Traer grupos
        dispatch(getAssignmentsByAreaApi());
        return () => {

        };
    }, []);


    useEffect(() => {

        if (successList) {
            // console.log("data");
            // console.log(data);

            setSpecificTotal(data.specificTotal);
            setGeneralTotal(data.generalTotal);

            setGeneral(data.general);
            setSpecific(data.specific);

        }

        return () => {
            // dispatch(resetDeleteProductApi());
        };
    }, [getAssignmentsByArea]);

    const getIntroOfPage = (label) => {

        // alert(label); 

        return label.toFixed(2);
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            let myCustomValue = payload[0].value;
            return (
                <div className={classes.customTooltip}>
                    <span className="label">{`${label}`}</span><br></br>
                    {/* <p className={classes.blueColorFont}>{`# BitCoins: ${getIntroOfPage(payload[0].value)}`}</p> */}
                    <NumberFormat
                        value={myCustomValue.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'\u20BF '}
                        renderText={value => <span className={classes.blueColorFont}><b>{value}</b></span>} />

                </div>
            );
        }

        return null;
    };


    return (
        <>
            <div className={classes.root}>

                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <h1>Reporte de bitcoins por área de aprendizaje</h1>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <Paper className={classes.paper}>
                            <NumberFormat
                                value={specificTotal.toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'\u20BF '}
                                renderText={value => <h3 className={classes.blueColorFont}>Top Principales (<b>{value}</b>)</h3>} />
                            <div class="chartAll">
                                <ResponsiveContainer>
                                    <BarChart data={specific}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <XAxis dataKey="original" />
                                        <YAxis />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip content={<CustomTooltip />} />
                                        {/* <Tooltip  /> */}
                                        <Legend />
                                        <Bar type="monotone" name="# BitCoins" dataKey="amount" stroke="black" fill="#9fc33b" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                            <NumberFormat
                                value={generalTotal.toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'\u20BF '}
                                renderText={value => <h3 className={classes.blueColorFont}>Otros (<b>{value}</b>)</h3>} />
                            <div class="chartAll">
                                <ResponsiveContainer>
                                    <BarChart data={general}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <XAxis dataKey="original" />
                                        <YAxis />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip content={<CustomTooltip />} />
                                        {/* <Tooltip  /> */}
                                        <Legend />
                                        <Bar type="monotone" name="# BitCoins" dataKey="amount" stroke="black" fill="#9fc33b" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>

            </div>
            <Backdrop className={classes.backdrop} open={loadingList}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </>
    );
}

export default InsigniasAdminScreen;
