import {
    PRODUCTS_LIST_RESET,
    PRODUCTS_LIST_REQUEST,
    PRODUCTS_LIST_SUCCESS,
    PRODUCTS_LIST_FAIL,

    PRODUCT_MANAGEMENT_RESET,
    PRODUCT_MANAGEMENT_REQUEST,
    PRODUCT_MANAGEMENT_SUCCESS,
    PRODUCT_MANAGEMENT_FAIL,

    SAVE_PRODUCT_MANAGEMENT_RESET,
    SAVE_PRODUCT_MANAGEMENT_REQUEST,
    SAVE_PRODUCT_MANAGEMENT_SUCCESS,
    SAVE_PRODUCT_MANAGEMENT_FAIL,


    DELETE_PRODUCT_MANAGEMENT_RESET,
    DELETE_PRODUCT_MANAGEMENT_REQUEST,
    DELETE_PRODUCT_MANAGEMENT_SUCCESS,
    DELETE_PRODUCT_MANAGEMENT_FAIL,


    GET_CATEGORIES_RESET,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAIL,

    GET_RANDOM_CONFIGURATION_RESET,
    GET_RANDOM_CONFIGURATION_REQUEST,
    GET_RANDOM_CONFIGURATION_SUCCESS,
    GET_RANDOM_CONFIGURATION_FAIL,


} from '../constants/productManagementConstants';

function getRandomConfigurationReducer(state = { products: { categories: [], items: [] } }, action) {
    switch (action.type) {
        case GET_RANDOM_CONFIGURATION_RESET:
            return {
                loading: false,
                products: { categories: [], items: [] },
                success: false
            };
        case GET_RANDOM_CONFIGURATION_REQUEST:
            return { loading: true, products: action.payload };
        case GET_RANDOM_CONFIGURATION_SUCCESS:
            return { loading: false, products: action.payload, success: true };
        case GET_RANDOM_CONFIGURATION_FAIL:
            return { loading: false, error: action.payload, products: { categories: [], items: [] } };
        default:
            return state;
    }
}

function listProductManagementReducer(state = { products: [{ total: 0, items: [] }] }, action) {
    switch (action.type) {
        case PRODUCTS_LIST_RESET:
            return {
                loading: false,
                products: [{ total: 0, items: [] }],
                success: false
            };
        case PRODUCTS_LIST_REQUEST:
            return { loading: true, products: action.payload };
        case PRODUCTS_LIST_SUCCESS:
            return { loading: false, products: action.payload, success: true };
        case PRODUCTS_LIST_FAIL:
            return { loading: false, error: action.payload, products: [{ total: 0, items: [] }] };
        default:
            return state;
    }
}

function productManagementReducer(state = { product: [{ images: [] }] }, action) {
    switch (action.type) {
        case PRODUCT_MANAGEMENT_RESET:
            return {
                loading: false,
                product: [{ images: [] }],
                success: false
            };
        case PRODUCT_MANAGEMENT_REQUEST:
            return { loading: true, product: [{ images: [] }] };
        case PRODUCT_MANAGEMENT_SUCCESS:
            return { loading: false, product: action.payload, success: true };
        case PRODUCT_MANAGEMENT_FAIL:
            return { loading: false, error: action.payload, product: [] };
        default:
            return state;
    }
}


function saveProductManagementReducer(state = { product: [{ images: [] }] }, action) {
    switch (action.type) {
        case SAVE_PRODUCT_MANAGEMENT_RESET:
            return {
                loading: false,
                product: [{ images: [] }],
                success: false
            };
        case SAVE_PRODUCT_MANAGEMENT_REQUEST:
            return { loading: true, product: [{ images: [] }] };
        case SAVE_PRODUCT_MANAGEMENT_SUCCESS:
            return { loading: false, product: action.payload, success: true };
        case SAVE_PRODUCT_MANAGEMENT_FAIL:
            return { loading: false, error: action.payload, product: [] };
        default:
            return state;
    }
}


function deleteProductManagementReducer(state = { product: [] }, action) {
    switch (action.type) {
        case DELETE_PRODUCT_MANAGEMENT_RESET:
            return {
                loading: false,
                product: [],
                success: false
            };
        case DELETE_PRODUCT_MANAGEMENT_REQUEST:
            return { loading: true, product: [] };
        case DELETE_PRODUCT_MANAGEMENT_SUCCESS:
            return { loading: false, product: action.payload, success: true };
        case DELETE_PRODUCT_MANAGEMENT_FAIL:
            return { loading: false, error: action.payload, product: [] };
        default:
            return state;
    }
}


function getCategoriesReducer(state = { categories: [] }, action) {
    switch (action.type) {
        case GET_CATEGORIES_RESET:
            return { loading: false, categories: [], success: false };
        case GET_CATEGORIES_REQUEST:
            return { loading: true, categories: [] };
        case GET_CATEGORIES_SUCCESS:
            return { loading: false, categories: action.payload, success: true };
        case GET_CATEGORIES_FAIL:
            return { loading: false, error: action.payload, categories: [] };
        default:
            return state;
    }
}


export {
    productManagementReducer,
    listProductManagementReducer,
    saveProductManagementReducer,
    deleteProductManagementReducer,
    getCategoriesReducer,
    getRandomConfigurationReducer
};
