export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_RECOVERY_RESET = 'USER_RECOVERY_RESET';
export const USER_RECOVERY_REQUEST = 'USER_RECOVERY_REQUEST';
export const USER_RECOVERY_SUCCESS = 'USER_RECOVERY_SUCCESS';
export const USER_RECOVERY_FAIL = 'USER_RECOVERY_FAIL';


export const USER_LOGOUT = 'USER_LOGOUT';


