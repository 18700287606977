import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { listProducts } from '../actions/productActions';
import Rating from '../components/Rating';
// import ReactDOM from 'react-dom';


import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';

import BannerSchools from '../components/BannerSchools';
import BannerPartners from '../components/BannerPartners';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

function HomeScreen(props) {

  const getGeneralSchoolInformation = useSelector(state => state.getGeneralSchoolInformation);
  const { info } = getGeneralSchoolInformation;


  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const useStyles = makeStyles((theme) => ({
    root: {
      height: 'calc(100vh - 64px)',
      margin: '0',
      padding: '0'
    },
    image: {
      backgroundImage: userInfo ? 'url("/images/Home2.jpg")' : 'url("/images/home_02.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      // alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    baseColor: {
      color: '#9fc33b',
    },
    marginText: {
      margin: theme.spacing(5),
    },
    face: {
      backgroundColor: "transparent",
      // color: theme.palette.common.black,
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    insta: {
      backgroundColor: "transparent",
      // color: theme.palette.common.black,
      position: 'absolute',
      bottom: theme.spacing(11),
      right: theme.spacing(2),
    },
    whats: {
      backgroundColor: "transparent",
      // color: theme.palette.common.black,
      position: 'absolute',
      bottom: theme.spacing(20),
      right: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  return (
    <>

      <Grid container component="main" className={classes.root} >
        <CssBaseline />

        <Grid
          item xs={12} sm={8} md={5}
          component={Paper}
          elevation={6}
          square>

          <Grid
            container
            direction="column"
            justify="space-between"
            // alignItems="center"
            style={{
              height: 'calc(100%)',
            }}
          >

            <div className={classes.marginText}>

              {userInfo ? (
                <>
                  <br></br>
                  <br></br>
                  <Typography variant="h3" >
                    <b>COLEGIO</b>
                  </Typography>
                  <Typography variant="h3" className={classes.baseColor} >
                    <b>{info.schoolName}</b>
                  </Typography>
                </>
              ) : (
                <>
                  <br></br>
                  <br></br>
                  <br></br>
                </>
              )}



              {userInfo ? (
                <>
                  <Typography variant="subtitle1">
                    <br></br>
                    <br></br>
                    <b>
                      {info.privateHomePhrase}
                    </b>
                    <br></br>
                    <br></br>
                    <br></br>
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="subtitle1">
                    <br></br>
                    <br></br>
                    <b>
                      {info.publicHomePhrase}
                    </b>
                    <br></br>
                    <br></br>
                    <br></br>
                  </Typography>
                </>
              )}



            </div>

            <div id="cf">
              <img style={{ width: 'calc(100%)' }} class="bottom" src="/images/Banner2-short.jpg" />
              <img style={{ width: 'calc(100%)' }} class="top" src="/images/Banner1-short.jpg" />
            </div>

          </Grid>


        </Grid>


        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        {/* <img style={{ width: 'calc(100%)' }} class="bottom" src="/images/Banner2-short.jpg" /> */}

        <a href="https://wa.me/522223244932?text=Me%20interesa%20el%20sistema" target="_blank">
          <Fab aria-label="add1" className={classes.face}>
            <img style={{ width: '100%' }} src="/images/whats_02.png" />
          </Fab>
        </a>
        <a href="https://www.instagram.com/schoolmarketmx/" target="_blank">
          <Fab aria-label="add2" className={classes.insta}>
            <img style={{ width: '100%' }} src="/images/insta_02.png" />
          </Fab>
        </a>
        <a href="https://www.facebook.com/schoolmarketmx" target="_blank">
          <Fab aria-label="add3" className={classes.whats}>
            <img style={{ width: '100%' }} src="/images/face_02.png" />
          </Fab>
        </a>

      </Grid>

    </>
  );
}





export default HomeScreen;
