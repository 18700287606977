import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dateFormat from 'dateformat';

import {
    getGroupPurchasesApi,
    getStudentsGroupPurchasesApi
} from '../actions/groupPurchasesActions'

import NumberFormat from 'react-number-format';


import { makeStyles, withStyles } from '@material-ui/core/styles';
import ProductDetailComponent from '../components/ProductDetailComponent';
// MATERIAL UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DescriptionIcon from '@material-ui/icons/Description';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function GroupPurchasesScreen(props) {

    const getStudentsGroupPurchases = useSelector(state => state.getStudentsGroupPurchases);
    const { loading: loadingList, data: list, error: errorList, success: successList } = getStudentsGroupPurchases;

    const getGroupPurchases = useSelector(state => state.getGroupPurchases);
    const { loading: loadingGroups, error: errorGroups, success: successGroups, groups } = getGroupPurchases;





    const [dataTable, setDataTable] = useState([]);

    const [columnFilter, setColumnFilter] = useState("date");
    const [isAscending, setIsAscending] = useState(false);
    const [teacherChecked, setTeacherChecked] = useState([]);

    const [showBackdrop, setShowBackdrop] = useState(false);

    const [pagePagination, setPagePagination] = useState(1);
    const [ipp, setIpp] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const [groupId, setGroupId] = useState('all');
    const [groupName, setGroupName] = useState('Sin nombre');
    const [teacherName, setTeacherName] = useState('No asignado');

    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({

        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: '#f5f5f5',
            minHeight: 'calc(100vh - 64px)',
        },
        paper: {
            padding: theme.spacing(2),
            // textAlign: 'right',
            color: theme.palette.text.secondary,
        },
        paper2: {
            padding: theme.spacing(2),
            // textAlign: 'left',
            color: theme.palette.text.secondary,
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,

        },
        margin: {
            margin: theme.spacing(2),

        },
        btnMargin: {
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(3),

        },
        table: {
            minWidth: 700,
        },
        iconbuton: {
            '& > *': {
                margin: theme.spacing(0),
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        paginationCls: {
            padding: theme.spacing(1),
            textAlign: 'right',
        },
        btnHeader: {
            color: "#ffffff",
            margin: theme.spacing(1),
        },
        alignCenter: {
            textAlign: 'center',
        },
        rightAlng: {
            textAlign: 'left',
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    useEffect(() => {
        // Traer grupos
        dispatch(getGroupPurchasesApi());
        return () => {
            // dispatch(resetDeleteProductApi());
        };
    }, []);


    useEffect(() => {


        if (successList) {
            // handleSetMarket();

            if (list.teachers.length > 0) {

                let teacherNamesTmp = "";

                for (let idx in list.teachers) {
                    teacherNamesTmp = teacherNamesTmp + list.teachers[idx].name + ', ';
                }

                teacherNamesTmp = teacherNamesTmp.slice(0, -2);

                setTeacherName(teacherNamesTmp);

            } else {
                setTeacherName("No asignado");
            }

            if (list.items.length > 0) {
                setUpPaging(list.items);
            }

        }

        return () => {

        };
    }, [getStudentsGroupPurchases]);


    const setUpPaging = (items) => {

        var page = pagePagination,
            per_page = ipp,
            offset = (page - 1) * per_page,

            paginatedItems = items.slice(offset).slice(0, per_page),

            total_pages = Math.ceil(items.length / per_page);

        // Calcular la enumaracion
        let initialCount = ((page - 1) * per_page);

        for (let idx in paginatedItems) {
            initialCount++;
            let studentDataTmp = list.students.find(o => o.id === paginatedItems[idx].user_id);
            // console.log("groups"); 
            // console.log(list);
            paginatedItems[idx].consec = initialCount;
            paginatedItems[idx].studentName = studentDataTmp ? studentDataTmp.name : 'Error';
            paginatedItems[idx].nickname = studentDataTmp ? studentDataTmp.nickname : 'Error';
        }

        let arryProducts = {
            page: page,
            per_page: per_page,
            pre_page: page - 1 ? page - 1 : null,
            next_page: (total_pages > page) ? page + 1 : null,
            total: items.length,
            total_pages: total_pages,
            items: paginatedItems
        };

        setTotalPages(total_pages);

        setDataTable(paginatedItems);

        console.log("arryProducts");
        console.log(arryProducts);

        // alert("creando paginación");

    }


    // *** SIRVE
    useEffect(() => {

        if (loadingGroups || loadingList) {
            setShowBackdrop(true);
        } else {
            setShowBackdrop(false);
        }

        if (successGroups) {
            // alert("Todo ok");
            console.log("groups-todo ok");
            console.log(groups);

            setDataTable(groups.products);

        }

        return () => {
        };
    }, [getGroupPurchases, getStudentsGroupPurchases]);


    const handleGroupChange = (event) => {

        setDataTable([]);
        setPagePagination(1);
        setIsAscending(false);
        setIpp(10);
        setColumnFilter("date");

        let group = event.target.value;
        setGroupId(group);
        dispatch(getStudentsGroupPurchasesApi(group));
        let obj = groups.groups.find(o => o.id === group);
        setGroupName(obj ? obj.name : 'No encontrado');
    };


    const handleChangeFilter = (column) => () => {

        let chn = isAscending;

        if (columnFilter === column) {
            setIsAscending(!chn);
        } else {
            setColumnFilter(column);
        }

    };

    const obtenerFechaConvertida = (fecha) => {
        let utcSeconds = fecha;
        let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds);

        return dateFormat(d, 'd"/"mm"/"yyyy  " "h:MM:ss TT');

        // 'dd"/"mm"/"yyyy'
    }


    const handleChangePage2 = (event, value) => {
        setPagePagination(value);
    };

    const handleChange = (event) => {
        setIpp(event.target.value);
        setPagePagination(1);
    };


    useEffect(() => {
        // // dispatch(listProducsApi(pagePagination, ipp));
        // dispatch(listProducsApi('all', "", pagePagination, ipp, columnFilter, isAscending, list.items));
        if (dataTable.length > 0) {


            // list.items

            // alert(columnFilter);
            // alert(isAscending);

            switch (columnFilter) {
                case 'date':

                    let itemsArry = list.items;

                    if (isAscending) {
                        itemsArry.reverse();
                    } else {
                        itemsArry.reverse();
                    }

                    setUpPaging(itemsArry);

                    break;
                case 'Manzanas':
                    console.log('El kilogramo de manzanas cuesta $0.32.');
                    break;
                case 'Platanos':
                    console.log('El kilogramo de platanos cuesta $0.48.');
                    break;
                case 'Cerezas':
                    console.log('El kilogramo de cerezas cuesta $3.00.');
                    break;
                case 'Mangos':
                case 'Papayas':
                    console.log('El kilogramo de mangos y papayas cuesta $2.79.');
                    break;
                default:
                    console.log('Lo lamentamos, por el momento no disponemos de ' + '.');
            }



        }

        return () => {
        };
    }, [pagePagination, ipp, isAscending, columnFilter]);

    return (
        <>
            <div className={classes.root}>
                <h1>Compras realizadas por productos</h1>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <Paper className={classes.paper}>

                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                            >

                                {/* <FormControl size="small" className={classes.formControl}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        variant="outlined"
                                        value={groupId}
                                        onChange={handleGroupChange}
                                    >
                                        <MenuItem value="all">Seleccionar</MenuItem>
                                        {groups.groups && groups.groups.map((value, index) => {
                                            return (
                                                <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl> */}

                                {/* <Button
                                    variant="contained"
                                    color="primary"
                                    // onClick={handleOpenDialog(null, 'new')}
                                    startIcon={<DescriptionIcon />}
                                    className={classes.btnMargin}
                                >
                                    Generar reporte
                                </Button> */}



                                {dataTable && dataTable.length > 0 ? (
                                    <>
                                        <NumberFormat value={groups.total.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'\u20BF '} renderText={value => <h3 className={classes.rightAlng}>Compras Totales: {value}</h3>} />
                                        {/* <h4 className={classes.rightAlng}>Compras Totales: {`\u20BF  ${groups.total.toLocaleString(navigator.language, { minimumFractionDigits: 2 })} ${groups.total.toFixed(2)}`}</h4> */}
                                    </>
                                ) : (<>

                                </>)}

                            </Grid>

                            <br></br>

                            {dataTable && dataTable.length > 0 ? (
                                <>

                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="right">#</StyledTableCell>
                                                    {/* <StyledTableCell>
                                                        <Button
                                                            startIcon={columnFilter === 'title' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'title' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('group')}
                                                            className={classes.btnHeader}>
                                                            Grupo
                                                                </Button>
                                                    </StyledTableCell> */}
                                                    <StyledTableCell >
                                                        <Button
                                                            startIcon={columnFilter === 'category' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'category' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('teacher')}
                                                            className={classes.btnHeader}>
                                                            Ticket
                                                                </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell >
                                                        <Button
                                                            startIcon={columnFilter === 'stock' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'stock' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('name')}
                                                            className={classes.btnHeader}>
                                                            Nombre Alumno
                                                                </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell >
                                                        <Button
                                                            startIcon={columnFilter === 'price' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'price' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('nickname')}
                                                            className={classes.btnHeader}>
                                                            Alias alumno
                                                                </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell >
                                                        <Button
                                                            startIcon={columnFilter === 'price' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'price' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('product')}
                                                            className={classes.btnHeader}>
                                                            Nombre Producto
                                                                </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell >
                                                        <Button
                                                            startIcon={columnFilter === 'price' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'price' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('product')}
                                                            className={classes.btnHeader}>
                                                            Cantidad
                                                                </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <Button
                                                            startIcon={columnFilter === 'price' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'price' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('price')}
                                                            className={classes.btnHeader}>
                                                            Precio Unitario
                                                                </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <Button
                                                            startIcon={columnFilter === 'price' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'price' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('price')}
                                                            className={classes.btnHeader}>
                                                            Total
                                                                </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <Button
                                                            startIcon={columnFilter === 'date' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'date' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('date')}
                                                            className={classes.btnHeader}>
                                                            Fecha Compra
                                                                </Button>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>

                                                {dataTable && dataTable.map(function (row, idx) {
                                                    let consect = idx + 1;
                                                    return (
                                                        <StyledTableRow key={row.id + "-" + consect}>
                                                            <StyledTableCell align="right">{consect}</StyledTableCell>
                                                            {/* <StyledTableCell >
                                                                {groupName}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{teacherName}</StyledTableCell> */}
                                                            <StyledTableCell>{row.id}</StyledTableCell>
                                                            <StyledTableCell>{row.userName}</StyledTableCell>
                                                            <StyledTableCell>{row.userNickname}</StyledTableCell>
                                                            <StyledTableCell>{row.name}</StyledTableCell>
                                                            <StyledTableCell align="right">{row.qty}</StyledTableCell>
                                                            <StyledTableCell align="right"><b>&#x20BF;</b> {parseFloat(row.price).toFixed(2)}</StyledTableCell>
                                                            <StyledTableCell align="right"><b>&#x20BF;</b> {parseFloat(row.price * row.qty).toFixed(2)}</StyledTableCell>
                                                            <StyledTableCell >{obtenerFechaConvertida(row.timestamp._seconds)}</StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>



                                    {/* <Grid container direction="row" alignItems="center">
                                        <Grid item className={classes.paginationCls}>
                                            <Pagination count={totalPages} color="primary" page={pagePagination} onChange={handleChangePage2} showFirstButton showLastButton />
                                        </Grid>
                                        <Grid item className={classes.paginationCls}>
                                            <span>| &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Elementos por página:</span>
                                        </Grid>
                                        <Grid item className={classes.paginationCls}>
                                            <FormControl>
                                                <Select
                                                    labelId="demo-customized-select-label"
                                                    id="demo-customized-select"
                                                    value={ipp}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={5}>5</MenuItem>
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={25}>25</MenuItem>
                                                    <MenuItem value={50}>50</MenuItem>
                                                    <MenuItem value={100}>100</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}

                                </>
                            ) : (<>
                                <Grid item sm={12} md={12}>
                                    <Paper className={classes.paper}>
                                        <div className={classes.alignCenter}>
                                            <h2>{loadingGroups || loadingList ? 'Obteniendo compras' : 'No existen compras'}</h2>
                                        </div>
                                    </Paper>
                                </Grid>
                            </>)}

                        </Paper>
                    </Grid>
                </Grid>

            </div>
            <Backdrop className={classes.backdrop} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </>
    );
}


export default GroupPurchasesScreen;
