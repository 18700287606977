import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';

import FormHelperText from '@material-ui/core/FormHelperText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';

import TextField from '@material-ui/core/TextField';
import AddIcon from "@material-ui/icons/Add";
import { Fab } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import Rating from '@material-ui/lab/Rating';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

import NumberFormat from 'react-number-format';

import {
    resetroductManagement,
    reseSaveProductManagement,
    saveProductManagementApi,
    updateProductManagementApi,
    getCategoriesApi,
    resetCategoriesApi
} from '../actions/productManagementActions';

import {
    IMAGES_URL
} from '../constants/urlConstants';


const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: 'Very Satisfied',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

function ProductDetailComponent(props) {

    const imageUrl = IMAGES_URL;

    const dispatch = useDispatch();

    const [operationType, setOperationType] = useState('see');
    const [principalLegend, setPrincipalLegend] = useState('Infomación');
    const [btnLegend, setBtnLegend] = useState('Cerrar');

    const productManagement = useSelector(state => state.productManagement);
    const { product } = productManagement;

    const saveProductManagement = useSelector(state => state.saveProductManagement);
    const { loading: loadingSave, error: errorSave, success: successSave } = saveProductManagement;


    const getCategories = useSelector(state => state.getCategories);
    const { categories, success: successCategories } = getCategories;

    const [cat, setCat] = useState([]);


    const [values, setValues] = useState({
        textmask: '(1  )    -    ',
        numberformat: '',
    });

    const handleChange = (event) => {
        if (event.target.value >= 0) {
            setPrice(event.target.value);
            setValues({
                ...values,
                [event.target.name]: event.target.value,
            });
        }
    };


    const handleCategoryChange = (event) => {
        let valor = event.target.value;
        setCategoryId(valor);
        if (valor) {
            setCategoryIdError("");
        } else {
            setCategoryIdError("Indica una categoría válida");
        }
    };


    // Datos del producto
    const [id, setId] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");
    const [price, setPrice] = useState(0);
    const [stock, setStock] = useState(0);
    const [specifications, setSpecifications] = useState("");
    const [comments, setComments] = useState("");
    const [rating, setRating] = useState(0);
    const [images, setImages] = useState([]);

    const [authorName, setAuthorName] = useState("");


    const [categoryId, setCategoryId] = useState("");


    const [titleError, setTitleError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [priceError, setPriceError] = useState("");
    const [stockError, setStockError] = useState("");
    const [specificationsError, setSpecificationsError] = useState("");
    const [commentsError, setCommentsError] = useState("");
    const [otherError, setOtherError] = useState("");

    const [categoryIdError, setCategoryIdError] = useState("");


    const [disabledInputs, setDisabledInputs] = useState(true);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [collapseOpen, setCollapseOpen] = useState(false);


    const [openAdvice, setOpenAdvice] = useState(false);


    useEffect(() => {
        dispatch(getCategoriesApi());
        return () => {
            dispatch(resetroductManagement());
            dispatch(resetCategoriesApi());
            dispatch(reseSaveProductManagement());
        };
    }, []);

    useEffect(() => {

        if (loadingSave) {
            setShowBackdrop(true);
        } else {
            setShowBackdrop(false);
        }

        if (successSave) {
            props.onchange(false, 1);
        }

        if (errorSave) {
            errorSave.forEach(
                function myFunction(item, index) {
                    switch (item.param) {
                        case 'title':
                            setTitleError(item.msg);
                            break;
                        case 'description':
                            setDescriptionError(item.msg);
                            break;
                        case 'category':
                            // setCategoryError(item.msg);
                            setCategoryIdError(item.msg)
                            break;
                        case 'price':
                            setPriceError(item.msg);
                            break;
                        case 'stock':
                            setStockError(item.msg);
                            break;
                        case 'specifications':
                            setSpecificationsError(item.msg);
                            break;
                        case 'comments':
                            setCommentsError(item.msg);
                            break;
                        default:
                            setOtherError(item.msg);
                            setCollapseOpen(true);
                            break;
                        // code block
                    }
                });
        }

        return () => {
        };
    }, [saveProductManagement]);

    useEffect(() => {

        // categories
        if (successCategories) {
            setCat(categories.categories);
            // console.log("categories.categories");
            // console.log(categories.categories);
        }

        return () => {

        };
    }, [getCategories]);

    useEffect(() => {

        if (product.id) {
            setId(product.id);
            setTitle(product.title);
            setDescription(product.description);
            setCategoryId(product.category);
            setPrice(product.price);
            setStock(product.stock);
            setSpecifications(product.specifications);
            setComments(product.comments);
            setRating(product.rating);
            setImages(product.images);
            setAuthorName(product.authorName);
            setValues({
                ...values,
                ['numberformat']: product.price,
            });
        }

        return () => {

        };
    }, [productManagement]);


    useEffect(() => {
        setOperationType(props.type);

        switch (props.type) {
            case 'new':
                // code block
                setDisabledInputs(false);
                setPrincipalLegend("Nuevo producto");
                setBtnLegend("Crear Producto");
                break;
            case 'see':
                // code block
                setDisabledInputs(true);
                setPrincipalLegend("Detalle del producto");
                setBtnLegend("Cerrar");
                break;
            case 'edit':
                // code block
                setDisabledInputs(false);
                setPrincipalLegend("Editar producto");
                setBtnLegend("Actualizar Producto");
                break;
            default:
            // code block
        }

    }, [props]);

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            // width: 500,
            // height: 1050,
        },
        icon: {
            color: 'rgba(255, 255, 255, 0.54)',
        },
        appBar: {
            position: 'relative',
            backgroundColor: '#000',
            color: '#9fc33b',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        root: {
            flexGrow: 1,
            marginTop: 50
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        colapse: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },

    }));

    const classes = useStyles();

    const handleSaveInformation = () => {

        // setOpenDialog(false);

        if (operationType === 'new' || operationType === 'edit') {

            if (id) {
                dispatch(updateProductManagementApi({
                    id,
                    title,
                    description,
                    categoryId,
                    price: price,
                    stock,
                    specifications,
                    comments,
                    rating,
                    images
                }));
            } else {
                dispatch(saveProductManagementApi({
                    title,
                    description,
                    categoryId,
                    price: price,
                    stock,
                    specifications,
                    comments,
                    rating,
                    images
                }));
            }


        } else {
            props.onchange(false, 0);
        }
    };


    const handleCloseDialog = () => {
        // setOpenDialog(false);
        props.onchange(false, 0);
    };


    // Establecer datos
    const titleChange = (event) => {
        setTitleError('');
        setTitle(event.target.value);
    };

    const descriptionChange = (event) => {
        setDescriptionError('');
        setDescription(event.target.value);
    };

    // const categoryChange = (event) => {
    //     setCategoryError('');
    //     setCategory(event.target.value);
    // };

    const priceChange = (event) => {
        setPriceError('');
        setPrice(event.target.value);
    };

    const stockChange = (event) => {
        setStockError('');
        setStock(event.target.value);
    };

    const specificationsChange = (event) => {
        setSpecificationsError('');
        setSpecifications(event.target.value);
    };

    const commentsChange = (event) => {
        setCommentsError('');
        setComments(event.target.value);
    };


    const handleDeleteImage = (id) => () => {
        let newChecked = [...images];
        let myArray = newChecked.filter(function (obj) {
            return obj.id !== id;
        });
        setImages(myArray);
    };

    const imageChange = (event) => {
        if (images.length < 5) {
            // console.log(event.target.value);
            Main();
        } else {
            alert("Solo se permiten 5 imágenes");
        }
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const Main = async () => {
        const file = document.querySelector('#upload-photo').files[0];
        const img64 = await toBase64(file);

        if ((((img64.length * (3 / 4)) - 1) / 1024) > 520) {
            setOpenAdvice(true);
        } else {

            let newChecked = [...images]; // Se crea el nuevo array con los datos que y existen
            let tmpId = generate();
            newChecked.push({ id: tmpId, name: "Nombre imagen", source: img64 });
            setImages(newChecked);
        }

    };

    const handleCloseAdvice = () => {
        setOpenAdvice(false);
    };


    var ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var ID_LENGTH = 8;
    var generate = function () {
        var rtn = '';
        for (var i = 0; i < ID_LENGTH; i++) {
            rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
        }
        return rtn;
    }

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {principalLegend}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleSaveInformation}>
                        {btnLegend}
                    </Button>
                </Toolbar>
            </AppBar>
            <div className={classes.contenido}>
                <Grid direction="row"
                    justify="center"
                    alignItems="flex-start"
                    container>
                    <Grid item xs={12} sm={12} md={7}>
                        <div className={classes.colapse}>
                            <Collapse in={collapseOpen}>
                                <Alert
                                    severity="error"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setCollapseOpen(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                >
                                    <AlertTitle>Error</AlertTitle>
                                    <strong>{otherError}</strong>
                                </Alert>
                            </Collapse>
                        </div>
                        <h3>* Nombre del producto</h3>
                        <FormControl fullWidth
                            variant="outlined" >
                            <TextField
                                id="outlined-multiline-static1"
                                label="Nombre del producto"
                                variant="outlined"
                                value={title}
                                onChange={titleChange}
                                disabled={disabledInputs}
                                error={titleError.length > 0}
                                helperText={titleError && titleError}
                            />

                            {disabledInputs && disabledInputs && (
                                <>
                                      <h3>Creado por</h3>
                                        <TextField
                                            id="outlined-multiline-static7"
                                            label=""
                                            variant="outlined"
                                            value={authorName}
                                            disabled={disabledInputs}
                                        />
                                </>
                            )}

                            <h3>* Descripción</h3>
                            <TextField
                                id="outlined-multiline-static3"
                                label="Descripción del producto"
                                multiline
                                rows={4}
                                variant="outlined"
                                value={description}
                                disabled={disabledInputs}
                                onChange={descriptionChange}
                                error={descriptionError.length > 0}
                                helperText={descriptionError && descriptionError}
                            />
                            <h3>* Categoría del producto</h3>
                            {/* <InputLabel id="demo-simple-select-outlined-label">Categoría</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={categoryId}
                                onChange={handleCategoryChange}
                                error={categoryIdError.length > 0}
                                disabled={disabledInputs}
                                helperText={categoryIdError && categoryIdError}
                            >
                                <MenuItem value="">
                                    <em>Selecciona una de las siguiente opciones: </em>
                                </MenuItem>
                                {categories.categories && categories.categories.map((value, index) => {
                                    return (
                                        <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                    );
                                })}
                            </Select>
                            <FormHelperText>{categoryIdError}</FormHelperText>
                            {/* <TextField
                                id="outlined-multiline-static2"
                                label="Categoría del producto"
                                variant="outlined"
                                value={category}
                                disabled={disabledInputs}
                                onChange={categoryChange}
                                error={categoryError.length > 0}
                                helperText={categoryError && categoryError}
                            /> */}
                            <h3>* Precio unitario</h3>
                            <TextField
                                error={priceError.length > 0}
                                helperText={priceError && priceError}
                                disabled={disabledInputs}
                                label="Precio del producto"
                                value={values.numberformat}
                                onChange={handleChange}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                variant="outlined"
                            />
                            <h3>* Cantidad disponible</h3>
                            <TextField
                                id="outlined-multiline-static5"
                                label=""
                                type="number"
                                variant="outlined"
                                value={stock}
                                disabled={disabledInputs}
                                onChange={stockChange}
                                error={stockError.length > 0}
                                helperText={stockError && stockError}
                            />
                            <h3>* Especificaciones</h3>
                            <TextField
                                id="outlined-multiline-static6"
                                label="Especificaciones del producto"
                                variant="outlined"
                                value={specifications}
                                disabled={disabledInputs}
                                onChange={specificationsChange}
                                error={specificationsError.length > 0}
                                helperText={specificationsError && specificationsError}
                            />
                            <h3>* Comentarios</h3>
                            <TextField
                                id="outlined-multiline-static7"
                                label="Comentarios del producto"
                                variant="outlined"
                                multiline
                                rows={4}
                                value={comments}
                                disabled={disabledInputs}
                                onChange={commentsChange}
                                error={commentsError.length > 0}
                                helperText={commentsError && commentsError}
                            />

                            <h3>Calificación</h3>


                            {/* {rating && rating > 0 && rating <= 5 ? (
                                <Rating
                                    name="simple-controlled-rat2"
                                    defaultValue={rating}
                                    readOnly
                                    // getLabelText={(value) => customIcons[value].label}
                                    IconContainerComponent={IconContainer}
                                />
                            ) : (
                                    <Rating
                                        name="simple-controlled-rat"
                                        defaultValue={rating}
                                        readOnly
                                        IconContainerComponent={IconContainer}
                                    />
                                )} */}

                            <Rating
                                disabled
                                name="simple-controlled"
                                value={rating}
                                onChange={(event, newValue) => {
                                    setRating(newValue);
                                }}
                            />


                            <h3>* Imágen(es) del producto (Máximo 5 imágenes)</h3>
                            <GridList cellHeight={180} className={classes.gridList}>
                                <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                                    {/* <ListSubheader component="div">{images && product.images.length} Imágen{images && images.length > 1 ? 'es' : ''}</ListSubheader> */}
                                    <ListSubheader component="div">Imágen(es)</ListSubheader>
                                </GridListTile>
                                {images && images.map((tile) => (
                                    <GridListTile key={tile.id}>
                                        <img loading="lazy" src={tile.url ? imageUrl + tile.url : tile.source} alt={tile.url} />
                                        <GridListTileBar
                                            title={tile.title}
                                            subtitle={<span>Sin título</span>}
                                            actionIcon={
                                                <IconButton
                                                    aria-label={`info about ${tile.name}`}
                                                    className={classes.icon}
                                                    disabled={disabledInputs}
                                                    onClick={handleDeleteImage(tile.id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        />
                                    </GridListTile>
                                ))}
                            </GridList>
                        </FormControl>
                        <br></br>
                        <br></br>
                        <label htmlFor="upload-photo" >
                            <input
                                style={{ display: "none" }}
                                id="upload-photo"
                                name="upload-photo"
                                type="file"
                                disabled={disabledInputs || images.length > 4}

                                // value={description}
                                onChange={imageChange}
                            />
                            <Fab
                                disabled={disabledInputs || images.length > 4}
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"

                            >
                                <AddIcon /> &nbsp;Seleccionar imagen &nbsp;&nbsp;
                            </Fab>
                        </label>
                    </Grid>
                </Grid>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </div>
            <Backdrop className={classes.backdrop} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={openAdvice}
                onClose={handleCloseAdvice}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Error!"}</DialogTitle>
                <DialogContent>
                    <div>
                        Solo se permiten imágnes de máximo 500 kb <br></br><br></br>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseAdvice}
                        variant="contained"
                        color="secondary"
                        autoFocus>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
            decimalScale={2}
            fixedDecimalScale={true}
        />
    );
}


export default ProductDetailComponent;




