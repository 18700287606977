import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    saveTeacher,
    resetTeacherSave,
    deleteTeacher
} from '../actions/teacherActions';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

function TeacherComponent(props) {
    const dispatch = useDispatch();

    const [type, setType] = useState(props.type);

    const [id, setId] = useState(props.id);
    const [name, setName] = useState(props.name);
    const [email, setEmail] = useState(props.email);
    const [nickname, setNickname] = useState(props.nickname);
    const [grade, setGrade] = useState(props.grade);

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [nicknameError, setNicknameError] = useState('');
    const [gradeError, setGradeError] = useState('');

    const teacherSave = useSelector(state => state.teacherSave);
    const { loading, error, success } = teacherSave;

    useEffect(() => {
        return () => {
            dispatch(resetTeacherSave());
        };
    }, []);


    useEffect(() => {
        if (error) {
            error.forEach(
                function myFunction(item, index) {
                    switch (item.param) {
                        case 'name':
                            setNameError(item.msg);
                            break;
                        case 'email':
                            setEmailError(item.msg);
                            break;
                        case 'grade':
                            setGradeError(item.msg);
                            break;
                        case 'nickname':
                            setNicknameError(item.msg);
                            break;

                        default:
                        // code block
                    }
                });
        }

        if (success) {
            props.onchange(false, 2);
        }

        return () => {
            //
        };
    }, [teacherSave]);

    let textInput = useRef(null);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'right',
            color: theme.palette.text.secondary,
        },
        list: {
            width: '100%',
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        noSelected: {
            color: 'red',
        },
        selected: {
            color: 'green'
        },
        margin: {
            margin: theme.spacing(2),
        },
        cancel: {
            margin: theme.spacing(2),
            textAlign: 'left',
        },
        wrapper: {
            textAlign: 'right',
        }
    }));

    const classes = useStyles();


    // VALIDACIONES
    const checkName = (v) => {
        if (!v || v.trim().length === 0) {
            setNameError('Este campo no debe estar vacío');
        } else {
            setNameError("");
        }
        setName(v)
    }


    const checkEmail = (v) => {
        if (!v || v.trim().length === 0) {
            setEmailError('Este campo no debe estar vacío');
        } else {
            setEmailError("");
        }
        setEmail(v)
    }



    const checkNickname = (v) => {
        if (!v || v.trim().length === 0) {
            setNicknameError('Este campo no debe estar vacío');
        } else {
            setNicknameError("");
        }
        setNickname(v)
    }

    const checkGrade = (v) => {
        // if (!v || v.trim().length === 0) {
        //     setGradeError('Este campo no debe estar vacío');
        // } else {
            setGradeError("");
        // }
        setGrade(v)
    }



    // FIN VALIDACIONES

    const closeModal = event => {
        props.onchange(false, 1);
    }

    // OPERACIONES CON ESTUDIANTES
    const submitStudentHandler = (e) => {
        e.preventDefault();

        if (type < 2) {
            dispatch(
                saveTeacher({
                    id,
                    name,
                    email,
                    nickname,
                    grade
                })
            );
        } else {
            dispatch(
                deleteTeacher({
                    id
                })
            );
        }

    };

    return (
        <>

            {type < 2 ? (

                <form
                    className={classes.form}
                    onSubmit={submitStudentHandler}
                    noValidate >

                    <TextField
                        autoFocus
                        inputRef={textInput}
                        value={name}
                        error={nameError.length > 0}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Nombre completo"
                        name="name"
                        autoComplete="name"
                        onChange={(e) => checkName(e.target.value)}
                        helperText={nameError && nameError}
                        size="small"
                    />
                    <TextField
                        value={email}
                        error={emailError.length > 0}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        onChange={(e) => checkEmail(e.target.value)}
                        helperText={emailError && emailError}
                        size="small"
                        disabled={type == "1"}
                    />

                    <TextField
                        value={nickname}
                        error={nicknameError.length > 0}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="nickname"
                        label="Alias"
                        name="nickname"
                        autoComplete="nickname"
                        onChange={(e) => checkNickname(e.target.value)}
                        helperText={nicknameError && nicknameError}
                        size="small"
                        disabled={type == "1"}
                    />

                    <TextField
                        value={grade}
                        error={gradeError.length > 0}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="grade"
                        label="Grado"
                        name="grade"
                        autoComplete="grade"
                        onChange={(e) => checkGrade(e.target.value)}
                        helperText={gradeError && gradeError}
                        size="small"
                    />

                    <div className={classes.wrapper}>
                        <Button
                            onClick={closeModal}
                            variant="outlined"
                            color="primary"
                            className={classes.cancel} >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Guardar
                    </Button>
                    </div>

                </form>

            ) : (
                    <>
                        <div>Deseas borrar al profesor {name} ? <br></br><br></br><br></br></div>
                        <div className={classes.wrapper}>
                            <Button
                                onClick={closeModal}
                                variant="outlined"
                                color="primary"
                                className={classes.cancel} >
                                Cancelar
                            </Button>
                            <Button
                                onClick={submitStudentHandler}
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Confirmar
                            </Button>
                        </div>
                    </>
                )}


            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default TeacherComponent;
