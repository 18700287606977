
export const SAVE_STUDENT_RESET = 'SAVE_STUDENT_RESET';
export const SAVE_STUDENT_REQUEST = 'SAVE_STUDENT_REQUEST';
export const SAVE_STUDENT_SUCCESS = 'SAVE_STUDENT_SUCCESS';
export const SAVE_STUDENT_FAIL = 'SAVE_STUDENT_FAIL';
export const DELETE_STUDENT_RESET = 'DELETE_STUDENT_RESET';
export const DELETE_STUDENT_REQUEST = 'DELETE_STUDENT_REQUEST';
export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS';
export const DELETE_STUDENT_FAIL = 'DELETE_STUDENT_FAIL';

