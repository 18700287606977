import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AutoComplete } from 'antd';

// import InfiniteList from "./InfiniteList";

import { Carousel } from 'react-responsive-carousel';
import { addToCart, removeFromCart, buyProductReset } from '../actions/cartActions';

import {
    listStoreProducsApi,
    resetlistProducsApi,
    listStoreProducsRandomApi,
    getRandomConfiguration
} from '../actions/productManagementActions'

import {
    IMAGES_URL
} from '../constants/urlConstants';

import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ClearIcon from '@material-ui/icons/Clear';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import Divider from '@material-ui/core/Divider';

import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

import styled from "styled-components";
import PropTypes from "prop-types";
import useInfiniteScroll from "react-infinite-scroll-hook";


const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);


const ListContainer = styled.div`
  max-height: ${props => (props.scrollable ? "600px" : "auto")};
  max-width: ${props => (props.scrollable ? "600px" : "auto")};
  overflow: auto;
  background-color: #e4e4e4;
`;


const List = styled.ul`
  list-style: none;
  font-size: 16px;
  margin: 0;
  padding: 6px;
`;

const ListItem = styled.li`
  background-color: #fafafa;
  border: 1px solid #99b4c0;
  padding: 8px;
  margin: 4px;
`;

const ARRAY_SIZE = 10;
const RESPONSE_TIME = 2000;
const NUMBER_ITEMS_LOAD = 30;

function PrintImg() {
    return (
        <img
            src={
                "https://exitocol.vteximg.com.br/assets/vtex.file-manager-graphql/images/slider-blue-week-desktop___451bdd92df31482e0ece795773e265d9.jpg"
            }
        />
    );
}



const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        // margin: theme.spacing(2),

    },
    input: {
        borderRadius: 0,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 0,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: 'Very Satisfied',
    },
};


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}




function StorePreviewScreen(props) {

    const [hasNextPage, setHasNextPage] = useState(false);

    const [loading, setLoading] = useState(false);
    const [realizandoPeticion, setRealizandoPeticion] = useState(false);

    const [contadorItems, setContadorItems] = useState(1);
    const [endMessage, setEndMessage] = useState('');


    const [items, setItems] = useState([]);
    const [scrollContainer, setScrollContainer] = useState("window");
    // "window", "parent"

    const WAIT_INTERVAL = 1000;
    let timerID;

    const imageUrl = IMAGES_URL;

    const [state] = useState({
        vertical: 'bottom',
        horizontal: 'center',
    });

    const { vertical, horizontal } = state;

    const cart = useSelector(state => state.cart);

    const { cartItems } = cart;

    const listProductManagement = useSelector(state => state.listProductManagement);
    const { loading: loadingList, products: list, error: errorList, success: successList } = listProductManagement;


    /**INICIO RANDOM */
    const randomConfiguration = useSelector(state => state.getRandomConfiguration);
    const { loading: loadingRandom, products: listRandom, error: errorRandom, success: successRandom } = randomConfiguration;
    /**FIN RANDOM */

    const buyProductDone = useSelector(state => state.buyProductDone);
    const { success: successDone } = buyProductDone;


    const [pagePagination, setPagePagination] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [showBackdrop, setShowBackdrop] = useState(false);

    const [paginationLegend, setPaginationLegend] = useState("0 resultados");
    const [openDialog, setOpenDialog] = useState(false);

    const [product, setProduct] = useState([]);


    const [itemsId, setItemsId] = useState([]);

    const [cantSelect, setCantSelect] = useState('1');

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [showProductDone, setShowProductDone] = useState(false);


    const [productSearch, setProductSearch] = useState('');
    const [categoryId, setCategoryId] = useState("all");

    const [scrollParent, setScrollParent] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getRandomConfiguration());

        return () => {
            dispatch(resetlistProducsApi());
            dispatch(buyProductReset());
        };
    }, []);

    useEffect(() => {
        // if (list.total > 0) {
        //     setTotalPages(list.total_pages);
        // } else {
        //     setTotalPages(0);
        // }
        // if (loadingList) {
        //     setShowBackdrop(true);
        // } else {
        //     setShowBackdrop(false);
        // }

        // let start = 0;
        // let end = 0;

        // let page = roughScale(list.page, 10);
        // let total_pages = roughScale(list.total_pages, 10);
        // let per_page = roughScale(list.per_page, 10);
        // let total = roughScale(list.total, 10);

        // if (page === total_pages) {
        //     start = ((page - 1) * (per_page)) + 1;
        //     end = total;
        // } else {
        //     start = ((page - 1) * (per_page)) + 1;

        //     end = list.page * list.per_page;


        // }

        // setPaginationLegend(`Mostrando ${start}-${end} de ${total} productos`);
        setPaginationLegend(``);


        if (successList) {

            if (categoryId === 'all') {

                // console.log("que onda!"); 
                // console.log(items);
                // console.log("Fin que onda!"); 


                let temProducts = items;
                let newProducts = list.items;
                temProducts.concat(newProducts);

                Array.prototype.push.apply(temProducts, newProducts);

                if (newProducts.length === 0) {
                    setHasNextPage(false);
                }

                setItems(temProducts);

                setRealizandoPeticion(false);

            } else {
                let newPersonalizedProducts = list.items;
                setItems(newPersonalizedProducts);
            }

            console.log("items");
            console.log(items);

            // setTimeout(() => {
            setLoading(false);
            // }, RESPONSE_TIME);

            if (hasNextPage === false) {
                setEndMessage('FIN');
            }

        }

        return () => {

        };
    }, [listProductManagement]);

    useEffect(() => {
        // // // dispatch(listStoreProducsRandomApi(categoryId, productSearch, pagePagination));
        return () => {
        };
    }, [pagePagination]);

    useEffect(() => {
        if (successDone) {
            setShowProductDone(true);
        }
        return () => {
        };
    }, [buyProductDone]);


    useEffect(() => {
        if (successRandom) {
            // console.log("Todo ok!");
            // console.log(listRandom);

            if (listRandom.items.length > NUMBER_ITEMS_LOAD) {
                setHasNextPage(true);
            }

            let productsArry = inicializeAndDoRequest(listRandom.items);

            dispatch(listStoreProducsRandomApi(categoryId, productSearch, productsArry));
        }
        return () => {
        };
    }, [randomConfiguration]);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            // backgroundColor: '#fff'
            // padding: theme.spacing(2),
            // backgroundColor: '#f5f5f5',
            minHeight: 'calc(100vh - 64px)',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '10px',
                paddingRight: '10px',
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: '6%',
                paddingRight: '6%',
            },
        },
        colorFondo: {
            // backgroundColor: 'red',
            minHeight: 'calc(100vh + 1px)',
        },
        paper: {
            padding: theme.spacing(0),
            marginBottom: theme.spacing(5),
            // padding: '0',
            // paddingBottom: '30',
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        parent: {
            position: "relative",
            // width: 200,
            height: '200',
        },
        backdrop: {
            position: "absolute",
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: 'red',
            color: '#000',

        },
        btnAlign: {
            justifyContent: 'center'
        },
        cardConf: {

        },
        alignCartBtn: {
            textAlign: 'right',
        },
        cartBtn: {
            '& > *': {
                margin: theme.spacing(0),
            },
        },
        appBar: {
            position: 'relative',
            backgroundColor: '#000',
            color: '#9fc33b',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        modalRoot: {
            flexGrow: 1,
            padding: 20,
            // marginTop: 50,
            paddingTop: 30,
            backgroundColor: '#fff',
        },
        marginSelect: {
            marginRight: theme.spacing(1),
        },
        formControl: {
            minWidth: 160,
        },
        rootSearch: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
        maginNotFound: {
            marginTop: theme.spacing(5),
        },
        alignSameLine: {
            display: 'flex',
            // alignItems: 'center',
        },
    }));

    const handleChangePage2 = (event, value) => {
        setPagePagination(value);
    };

    const classes = useStyles();

    // getRandomItems
    const inicializeAndDoRequest = (listadoIdExistente) => {

        // ALGORITMO
        // Obtener 10 elementos que no estén en * listadoIdExistente
        // 1. Quitar listadoIdExistente de listRandom.items

        // 2. Obtener 10 elementos aleatorios.
        let arr = listadoIdExistente;
        let n = NUMBER_ITEMS_LOAD;

        var result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len) {
            setHasNextPage(false);
            // setEndMessage('FIN');
            return arr;
        }


        // while (n--) {
        //     var x = Math.floor(Math.random() * len);
        //     result[n] = arr[x in taken ? taken[x] : x];
        //     taken[x] = --len in taken ? taken[len] : len;
        // }

        // Quitar los elementos aleatorios
        // result

        var size = NUMBER_ITEMS_LOAD;
        var itemsL = arr.slice(0, size).map(i => {
            return i;
        });

        let arryCut = listadoIdExistente.filter(function (el) {
            return itemsL.indexOf(el) < 0;
        });

        setItemsId(arryCut);

        return itemsL;
    }

    const roughScale = (x, base) => {
        let parsed = Number.parseInt(x, base);
        if (Number.isNaN(parsed)) {
            return 0;
        }
        return parsed;
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setProduct([]);
        setCantSelect('1');
    };

    const handleCloseShowProductDone = () => {
        setShowProductDone(false);
    };

    const handleClickOpen = (productView) => () => {
        console.log(productView);
        setProduct(productView);
        setOpenDialog(true);
    };

    const handleChangeSelect = (event) => {
        setCantSelect(event.target.value);
    };


    const handleAddCart = (product) => () => {
        

    };

    // Cerrar el snackbar
    const CloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    const handleTextFieldChange = (e) => {
        let value = e.target.value;
        setProductSearch(value);
    };

    const handleDoSearchText = (e) => {
        e.preventDefault();

        setHasNextPage(false);
        setEndMessage("");
        setItems([]);
        dispatch(listStoreProducsRandomApi(categoryId, productSearch, []));
        // // // dispatch(listStoreProducsRandomApi(categoryId, productSearch, pagePagination));
    };

    const deleteTerm = () => {
        setProductSearch("");
        // // // dispatch(listStoreProducsRandomApi(categoryId, "", pagePagination));

        if (categoryId === 'all') {
            // Es como estar haciendo un reset
            setHasNextPage(true);
            setItemsId([]);
            setEndMessage("");
            setItems([]);
            dispatch(getRandomConfiguration());
        } else {
            setHasNextPage(false);
            setEndMessage("");
            setItems([]);
            dispatch(listStoreProducsRandomApi(categoryId, "", []));
        }

    }

    // Selecciona una categoría
    const handleChange = (event) => {
        let cat = event.target.value;
        setCategoryId(cat);

        if (cat === "all") {
            // Si puede cargar mas elementos, se resetea todo
            setHasNextPage(true);
            setItemsId([]);
            setEndMessage("");
            setItems([]);
            dispatch(getRandomConfiguration());
        } else {
            // No puede cargar más elementos
            setHasNextPage(false);
            setEndMessage("");
            setItems([]);
            dispatch(listStoreProducsRandomApi(cat, productSearch, []));
        }

    };

    const labels = {
        0.5: 'Useless',
        1: 'Malo',
        1.5: 'Poor',
        2: 'Regular',
        2.5: 'Ok',
        3: 'Bien',
        3.5: 'Good',
        4: 'Muy bien',
        4.5: 'Excellent',
        5: 'Excelente!',
    };

    function loadItems(prevArray = [], startCursor = 0) {

        if (itemsId.length > 0) {
            let productsArryRadom = inicializeAndDoRequest(itemsId);
            dispatch(listStoreProducsRandomApi(categoryId, productSearch, productsArryRadom));
        }

        return new Promise(resolve => {
            setTimeout(() => {
                let newArray = prevArray;
                resolve(newArray);
            }, RESPONSE_TIME);
        });

    }


    function handleLoadMore() {

        setLoading(true);

        if (realizandoPeticion === false) {

            if (itemsId.length > 0) {
                setRealizandoPeticion(true);
                let productsArryRadom = inicializeAndDoRequest(itemsId);
                dispatch(listStoreProducsRandomApi(categoryId, productSearch, productsArryRadom));
            }

        }

        // loadItems(items, items.length).then(newArray => {
        //     setContadorItems(2);
        // });

    }

    const infiniteRef = useInfiniteScroll({
        loading,
        // This value is set to "true" for this demo only. You will need to
        // get this value from the API when you request your items.
        hasNextPage: hasNextPage,
        threshold: 500,
        onLoadMore: handleLoadMore,
        scrollContainer
    });


    return (
        <>
            <div className={classes.root}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <h1>Tienda</h1>
                    <IconButton aria-label="cart">
                        <StyledBadge badgeContent={cartItems.length} color="primary">
                            <ShoppingCartIcon fontSize="large" />
                        </StyledBadge>
                    </IconButton>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <FormControl size="small" className={classes.formControl}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="outlined"
                            value={categoryId}
                            onChange={handleChange}
                        >
                            <MenuItem value="all">Todos</MenuItem>

                            {listRandom.categories && listRandom.categories.map((value, index) => {
                                return (
                                    <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                );
                            })}

                        </Select>

                    </FormControl>


                    <Paper component="form" onSubmit={handleDoSearchText} className={classes.rootSearch}>
                        <InputBase
                            className={classes.input}
                            value={productSearch}
                            onChange={handleTextFieldChange}
                            placeholder="Buscar producto"
                            inputProps={{ 'aria-label': 'buscar producto' }}
                        />
                        <IconButton type="submit" color="primary" className={classes.iconButton} aria-label="buscar">
                            <SearchIcon />
                        </IconButton>
                        <Divider className={classes.divider} orientation="vertical" />
                        <IconButton color="secondary" disabled={productSearch.length == 0} onClick={deleteTerm} className={classes.iconButton} aria-label="directions">
                            <ClearIcon />
                        </IconButton>
                    </Paper>

                </Grid>
                <br></br>


                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-start"
                >

                    {items && items.length > 0 ? (
                        <b>{paginationLegend}</b>
                    ) : (
                        <></>
                    )}

                </Grid>

                <div className={classes.parent}>
                    <Backdrop className={classes.backdrop} open={showBackdrop}>
                        <CircularProgress />
                    </Backdrop>
                </div>

                <Grid container spacing={2} ref={infiniteRef} className={classes.colorFondo}>
                    {items && items.map(row => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={row.key}>
                            <Paper className={classes.paper} square={true} elevation={0}>
                                <Card className={classes.cardConf} square={true} elevation={0}>
                                    <CardActionArea onClick={handleClickOpen(row)}>
                                        <CardMedia
                                            component="img"
                                            alt={row.description}
                                            height="200"
                                            image={row.images.length > 0 ? imageUrl + row.images[0].url : "https://aeasa.com.mx/wp-content/uploads/2020/02/SIN-IMAGEN.jpg"}
                                            loading="lazy"
                                            title={row.description}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle1" component="h6">
                                                {row.title}
                                            </Typography>
                                            <Typography variant="h6" component="h6">
                                                <b>&#x20BF; {parseFloat(row.price).toFixed(2)}</b>
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions className={classes.btnAlign}>
                                        {row && row.stock > 0 ? (
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                fullWidth
                                                
                                                style={{
                                                    borderRadius: 0,
                                                }}
                                            >
                                                Agregar al carrito
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                size="small"
                                                disabled
                                                color="primary"
                                                fullWidth
                                                style={{
                                                    borderRadius: 0,
                                                }}
                                            >
                                                Agotado
                                            </Button>
                                        )}
                                    </CardActions>
                                </Card>
                            </Paper>
                        </Grid>
                    ))}
                    {items && items.length == 0 ? (
                        <Grid direction="row"
                            justify="center"
                            alignItems="flex-start"
                            container
                            className={classes.maginNotFound}
                        >
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <br></br>
                    <br></br>
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <h2>{endMessage != "FIN" && "Obteniendo productos..."}</h2>
                    </Grid>
                    <Grid direction="row"
                        justify="center"
                        alignItems="flex-start"
                        container
                    >
                        <h2>{endMessage}</h2>
                    </Grid>
                </Grid>



                <div id="cfb"  >
                    <img style={{ width: 'calc(100%)' }} className="topb" src="/images/Banner1.jpg" />
                    <img style={{ width: 'calc(100%)' }} className="bottomb" src="/images/Banner2.jpg" />
                </div>
            </div>



            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={CloseSnackbar}
                message="Se han realizado los cambios solicitados"
                key={vertical + horizontal}>
                <Alert onClose={CloseSnackbar} severity="success">
                    Producto agregado al carrito
                </Alert>
            </Snackbar>

            <Dialog fullScreen open={openDialog} onClose={handleCloseDialog} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Detalles del producto
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleCloseDialog}>
                            Cerrar
                        </Button>
                    </Toolbar>
                </AppBar>

                <div className={classes.modalRoot}>
                    <Grid direction="row"
                        justify="center"
                        alignItems="flex-start"
                        container
                    >
                        <Grid item xs={12} sm={12} md={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Carousel showArrows={true} >
                                        {product.images && product.images.map(function (row, idx) {
                                            let img = imageUrl + row.url;
                                            return (
                                                <div key={idx}>
                                                    <img src={img} />
                                                </div>
                                            )
                                        })}
                                    </Carousel>
                                </Grid>

                                <Grid item xs={12} sm={6} >
                                    <br></br>
                                    <br></br>
                                    <Typography variant="h6" component="h6">
                                        {product.title}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="h6" component="h6">
                                        <b>&#x20BF; {parseFloat(product.price).toFixed(2)}</b>
                                    </Typography>
                                    <br></br>


                                    {product.rating && product.rating > 0 && product.rating <= 5 ? (
                                        <Rating
                                            name="simple-controlled"
                                            defaultValue={product.rating}
                                            readOnly
                                            getLabelText={(value) => customIcons[value].label}
                                            IconContainerComponent={IconContainer}
                                        />
                                    ) : (
                                        <Rating
                                            name="simple-controlled"
                                            defaultValue={product.rating}
                                            readOnly
                                            IconContainerComponent={IconContainer}
                                        />
                                    )}


                                    <br></br>
                                    <br></br>
                                    <Typography variant="subtitle1" component="h3">
                                        {product.description}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="subtitle1" component="h3">
                                        {product.specifications}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="subtitle1" component="h3">

                                        {product.stock > 0 ? `(${product.stock} disponibles)` : 'NO DISPONIBLE'}

                                    </Typography>
                                    <br></br>

                                    {product && product.stock > 0 && (
                                        <>
                                            <Grid
                                                container
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                            >
                                                <FormControl className={classes.marginSelect}>
                                                    <NativeSelect
                                                        id="demo-customized-select-native"
                                                        value={cantSelect}
                                                        onChange={handleChangeSelect}
                                                        input={<BootstrapInput />}
                                                    >
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                        <option value={4}>4</option>
                                                        <option value={5}>5</option>
                                                        <option value={6}>6</option>
                                                        <option value={7}>7</option>
                                                        <option value={8}>8</option>
                                                        <option value={9}>9</option>
                                                        <option value={10}>10</option>
                                                    </NativeSelect>
                                                </FormControl>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    
                                                    style={{
                                                        borderRadius: 0,
                                                    }}
                                                >
                                                    Agregar al carrito
                                            </Button>
                                            </Grid>
                                        </>
                                    )}

                                </Grid>

                                <Grid item xs={12}>
                                    {product.reviews && product.reviews.length > 0 ? (
                                        <h2>Calificaciones del producto</h2>
                                    ) : (
                                        <h2>El producto aún no tiene calificaciones</h2>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    {product.reviews && product.reviews.map(function (row, idx) {
                                        return (
                                            <div key={idx}>
                                                <Rating
                                                    name={'customized-icons-set-view' + idx}
                                                    defaultValue={row.rating}
                                                    readOnly
                                                    getLabelText={(value) => customIcons[value].label}
                                                    IconContainerComponent={IconContainer}
                                                />
                                                <br></br>
                                                <b>"{row.titleComment}" </b>{row.comment}
                                                <br></br>
                                                <br></br>
                                            </div>
                                        )
                                    })}


                                </Grid>



                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>

            <Dialog
                open={showProductDone}
                onClose={handleCloseShowProductDone}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Compra exitosa</DialogTitle>
                <DialogContent>
                    <h3>Tu saldo ya fue actualizado, también puedes ver tus compras.</h3>
                    <br></br>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleCloseShowProductDone}
                    >
                        Cerrar aviso
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        component={Link} to={'/orders'}
                        style={{
                            borderRadius: 0,
                        }}
                    >
                        ver Mis compras
                    </Button>

                </DialogActions>
            </Dialog>


        </>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default StorePreviewScreen;