export const DATA_CONTACT_GET_REQUEST = 'DATA_CONTACT_GET_REQUEST';
export const DATA_CONTACT_GET_SUCCESS = 'DATA_CONTACT_GET_SUCCESS';
export const DATA_CONTACT_GET_FAIL = 'DATA_CONTACT_GET_FAIL';

export const DATA_CONTACT_SAVE_REQUEST = 'DATA_CONTACT_SAVE_REQUEST';
export const DATA_CONTACT_SAVE_SUCCESS = 'DATA_CONTACT_SAVE_SUCCESS';
export const DATA_CONTACT_SAVE_FAIL = 'DATA_CONTACT_SAVE_FAIL';
export const DATA_CONTACT_RESET_SUCCESS = 'DATA_CONTACT_RESET_SUCCESS';


export const DATA_CONTACT_POST_RESET = 'DATA_CONTACT_POST_RESET';
export const DATA_CONTACT_POST_REQUEST = 'DATA_CONTACT_POST_REQUEST';
export const DATA_CONTACT_POST_SUCCESS = 'DATA_CONTACT_POST_SUCCESS';
export const DATA_CONTACT_POST_FAIL = 'DATA_CONTACT_POST_FAIL';






