import React, { useEffect, useState } from 'react';
import {
  addToCart,
  removeFromCart,
  addToCartFrom,
  checkBalanceApi,
  resetCheckBalanceApi,
  removeAllProductsFromCart,
  buyProductDone,
  buyProductReset
} from '../actions/cartActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { red } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function CartScreen(props) {
  const [showBackdrop, setShowBackdrop] = useState(false);

  const cart = useSelector(state => state.cart);
  const { cartItems } = cart;

  const checkBalance = useSelector(state => state.checkBalance);
  const { loading, error, success, status } = checkBalance;

  const themess = useTheme();
  const mediumSize = useMediaQuery(themess.breakpoints.up('md'));

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [showContinousBtn, setShowContinousBtn] = useState(false);



  const [showLegendModal, setShowLegendModal] = useState([]);

  const [verifyPercent, setVerifyPercent] = useState(false);



  const useStyles = makeStyles((theme) => ({

    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      height: 'calc(100vh - 64px)',
      backgroundColor: '#f5f5f5'
      // height: 'calc(100vw)'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'right',
      color: theme.palette.text.secondary,
    },
    listing: {
      width: '100%',
      justifyContent: 'center',
      maxWidth: 350,
      backgroundColor: theme.palette.background.paper,
    },
    bgTotalColor: {
      backgroundColor: '#f5f5f5',

    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    alignCenter: {
      textAlign: 'center',
    }
  }));

  const classes = useStyles();

  const productId = props.match.params.id;
  const qty = props.location.search ? Number(props.location.search.split("=")[1]) : 1;
  const dispatch = useDispatch();
  const removeFromCartHandler = (productId) => {
    dispatch(removeFromCart(productId));
  }
  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
    return () => {
      dispatch(resetCheckBalanceApi());
      // dispatch(buyProductReset());
    };
  }, []);


  useEffect(() => {

    if (loading) {
      setShowBackdrop(true);
    } else {
      setShowBackdrop(false);
    }

    if (success) {

      let tmpLegend = [];
      // 0 Todo OK
      // 1 Saldo insuficiente
      // 2 Total del carrito es 0
      // 3 Gastando más del 50%
      // 4 Array : Producto agotado, inventario insuficiente
      // 5 Array Cambio de precios

      if (status.type === 0) {
        // dispatch(addToCart(productId, qty));
        handleDoBuy();

      } else if (status.type === 5 || status.type === 3) {
        // Esperar confirmación

        if (status.type === 5) {
          status.items.forEach(
            function myFunction(item, index) {
              tmpLegend.push({
                id: item.product,
                name: `Actualización de precio: el producto "${item.name}" incrementó a \u20BF${item.newPrice}`
              });

              dispatch(addToCartFrom({
                countInStock: item.countInStock,
                image: item.image,
                name: item.name,
                price: item.newPrice,
                product: item.product,
                qty: item.qty
              }, item.qty))

            });

          setShowLegendModal(tmpLegend);

        } else {
          setVerifyPercent(true);
          setShowLegendModal([{ id: 1, name: status.description }]);
        }

        setShowContinousBtn(true);
        setShowConfirmationModal(true);

      } else if (status.type === 4) {

        status.items.forEach(
          function myFunction(item, index) {
            tmpLegend.push({
              id: item.product,
              name: item.legend
            });
          });

        setShowLegendModal(tmpLegend);
        setShowContinousBtn(false);
        setShowConfirmationModal(true);

      } else {
        // No se puede
        setShowLegendModal([{ id: 1, name: status.description }]);
        setShowContinousBtn(false);
        setShowConfirmationModal(true);
      }

    }
    return () => {
      // 
    };
  }, [checkBalance]);



  const checkoutHandler = () => {

    setShowConfirmationModal(false);
    dispatch(checkBalanceApi(
      cartItems,
      parseFloat(cartItems.reduce((a, c) => a + c.price * c.qty, 0)).toFixed(2),
      verifyPercent
    )
    );
  }

  const handleDoBuy = () => {

    dispatch(removeAllProductsFromCart());
    props.history.push('/random');
    dispatch(buyProductDone());


  }


  const roughScale = (x, base) => {
    let parsed = Number.parseInt(x, base);
    if (Number.isNaN(parsed)) {
      return 0;
    }
    return parsed;
  }

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };



  return (
    <>
      <div className={classes.root}>
        <h1>Carrito</h1>

        <Grid
          container
          direction="column"
          justify="space-between"
          // alignItems="center"
          style={{
            // height: 'calc(100% - 151px)',
            // backgroundColor: 'red'
          }}
        >



          <Grid container spacing={2}>
            <Grid item sm={12} md={12}>
              <Paper className={classes.paper}>
                {cartItems && cartItems.length > 0 ? (
                  <>
                    <TableContainer component={Paper}>
                      <Table className={classes.table} size="small" aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>Producto</StyledTableCell>
                            <StyledTableCell align="right">Precio</StyledTableCell>
                            <StyledTableCell align="right">Cantidad</StyledTableCell>
                            <StyledTableCell align="right">Subtotal</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cartItems && cartItems.map(function (row, idx) {
                            return (
                              <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">
                                  <IconButton style={{ color: red[500] }} aria-label="delete" onClick={() => removeFromCartHandler(row.product)} className={classes.margin}>
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <img height="30" src={row.image} alt={"ddd"} />
                                </StyledTableCell>
                                <StyledTableCell>{row.name}</StyledTableCell>
                                <StyledTableCell align="right">

                                  <NumberFormat value={parseFloat(row.price).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'\u20BF '} renderText={value => <div>{value}</div>} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <ButtonGroup size="small" aria-label="contained  primary button group">
                                    <Button
                                      onClick={() => dispatch(addToCartFrom(row, row.qty - 1))}
                                      variant="contained"
                                      color="primary"
                                      style={{ borderRadius: 0 }}>-
                              </Button>
                                    <Button>{row.qty}</Button>
                                    <Button
                                      onClick={() => dispatch(addToCartFrom(row, row.qty + 1))}
                                      variant="contained"
                                      color="primary"
                                      style={{ borderRadius: 0 }}
                                    >+
                              </Button>
                                  </ButtonGroup>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <NumberFormat value={parseFloat(row.price * row.qty).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'\u20BF '} renderText={value => <div>{value}</div>} />
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div>
                      <br></br>
                      <br></br>
                      <Grid
                        container
                        direction="row"
                        justify={mediumSize ? 'flex-end' : 'flex-start'}   //"flex-end"
                        alignItems="flex-start"
                      >
                        <TableContainer component={Paper} className={classes.listing}>
                          <Table size="small" className={classes.bgTotalColor} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell colSpan={2}><h2><b>Total del carrito</b></h2></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>Subtotal ({cartItems.reduce((a, c) => a + c.qty, 0)} productos)</TableCell>
                                <TableCell> <NumberFormat value={parseFloat(cartItems.reduce((a, c) => a + c.price * c.qty, 0)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'\u20BF '} renderText={value => <div>{value}</div>} /></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell><b>Total</b></TableCell>
                                <TableCell><b><NumberFormat value={parseFloat(cartItems.reduce((a, c) => a + c.price * c.qty, 0)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'\u20BF '} renderText={value => <div>{value}</div>} /></b></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={2}>
                                  <Button
                                    variant="contained"
                                    // size="small"
                                    color="primary"
                                    fullWidth
                                    onClick={checkoutHandler}
                                    disabled={cartItems.length === 0}
                                    style={{
                                      borderRadius: 0,
                                    }}
                                  >
                                    Comprar
                                </Button>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </div>
                  </>
                ) : (
                    <>
                      <div className={classes.alignCenter}>
                        <h2>El carrito está vacío</h2>
                      </div>
                    </>
                  )}

              </Paper>
            </Grid>
          </Grid>

          <div id="cfb"  >
            <img style={{ width: 'calc(100%)' }} className="topb" src="/images/Banner1.jpg" />
            <img style={{ width: 'calc(100%)' }} className="bottomb" src="/images/Banner2.jpg" />
          </div>

        </Grid>

      </div>

      <Backdrop className={classes.backdrop} open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Aviso</DialogTitle>
        <DialogContent>

          {showLegendModal && showLegendModal.map(item =>
            <>
              <h3>* {item.name}</h3>
            </>
          )}
          <br></br>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationModal}>
            Cancelar
          </Button>

          {showContinousBtn && showContinousBtn && (
            <>
              <Button
                onClick={checkoutHandler}
                color="primary"
                autoFocus
                disabled={!showConfirmationModal}
                variant="contained"
                style={{
                  borderRadius: 0,
                }}
              >
                Continuar
              </Button>
            </>
          )}


        </DialogActions>
      </Dialog>


    </>
  );
}

export default CartScreen;