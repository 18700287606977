import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Cookie from 'js-cookie';
import {
  productListReducer,
  productDetailsReducer,
  productSaveReducer,
  productDeleteReducer,
  productReviewSaveReducer,
} from './reducers/productReducers';
import { cartReducer, checkBalanceReducer, buyProductDoneReducer, userOrdersReducer } from './reducers/cartReducers';
import {
  userSigninReducer,
  userRegisterReducer,
  userUpdateReducer,
  userRecoveryReducer
} from './reducers/userReducers';
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  myOrderListReducer,
  orderListReducer,
  orderDeleteReducer,
  addProductCommentReducer
} from './reducers/orderReducers';
import {
  studentDeleteReducer,
  studentSaveReducer
} from './reducers/studentReducers'
import {
  groupListReducer,
  groupDetailsReducer,
  groupDeleteReducer,
  groupSaveReducer,
  groupStudentUpdateListReducer,
  groupStudentSaveReducer,


} from './reducers/groupReducers'


import {
  enableUsersListReducer,
  enableUserDetailReducer,
  enableUserUpdateReducer
} from './reducers/enableUsersReducers'

import {
  getDataContactReducer,
  saveDataContactReducer,
  postCommentsReducer
} from './reducers/dataContactReducers'


import {
  GetTeacherGroupListReducer,
  UpdateTeacherGroupListReducer,
  TeacherSaveReducer
} from './reducers/teacherGroupReducers'

import {
  listProductManagementReducer,
  productManagementReducer,
  saveProductManagementReducer,
  deleteProductManagementReducer,
  getCategoriesReducer,
  getRandomConfigurationReducer
} from './reducers/productManagementReducers'


import {
  getGeneralSchoolInformationReducer
} from './reducers/generalSchoolInformationReducers'

import {
  buyCreditListReducer,
  justificationsListReducer,
  updateBuyCreditReducer,
  justificationSaveReducer
} from './reducers/buyCreditReducers'


import {
  getBalanceHistoryReducer
} from './reducers/balanceHistoryReducers'


import {
  getGroupPurchasesReducer,
  getStudentsGroupPurchasesReducer,
  getStudentWalletBalanceReducer,
  getAssignmentsByAreaReducer
} from './reducers/groupPurchasesReducers'


import {
  getSavingsConfReducer,
  saveSavingsConfReducer
} from './reducers/savingsConfReducers'



const cartItems = Cookie.getJSON('cartItems') || [];
const userInfo = Cookie.getJSON('userInfo') || null;
// const successRegistration = Cookie.getJSON('successRegistration') || null;

const initialState = {
  cart: { cartItems, shipping: {}, payment: {} },
  userSignin: { userInfo },
};
const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  cart: cartReducer,
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  productSave: productSaveReducer,
  productDelete: productDeleteReducer,
  productReviewSave: productReviewSaveReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  userUpdate: userUpdateReducer,
  myOrderList: myOrderListReducer,
  orderList: orderListReducer,
  orderDelete: orderDeleteReducer,

  groupStudentSave: groupStudentSaveReducer,

  studentDelete: studentDeleteReducer,
  studentSave: studentSaveReducer,
  groupList: groupListReducer,
  groupDetails: groupDetailsReducer,
  groupDelete: groupDeleteReducer,
  groupSave: groupSaveReducer,
  groupStudentUpdateList: groupStudentUpdateListReducer,

  enableUsersList: enableUsersListReducer,
  enableUserDetail: enableUserDetailReducer,
  enableUserUpdate: enableUserUpdateReducer,

  getDataContact: getDataContactReducer,
  saveDataContact: saveDataContactReducer,
  postComments: postCommentsReducer,

  getTeacherGroupList: GetTeacherGroupListReducer,
  updateTeacherGroupList: UpdateTeacherGroupListReducer,
  teacherSave: TeacherSaveReducer,

  listProductManagement: listProductManagementReducer,
  productManagement: productManagementReducer,
  saveProductManagement: saveProductManagementReducer,
  deleteProductManagement: deleteProductManagementReducer,
  getCategories: getCategoriesReducer,

  justificationSave: justificationSaveReducer,
  justificationsList: justificationsListReducer,
  buyCreditList: buyCreditListReducer,
  updateBuyCredit: updateBuyCreditReducer,

  getBalanceHistory: getBalanceHistoryReducer,
  checkBalance: checkBalanceReducer,
  buyProductDone: buyProductDoneReducer,


  userOrders: userOrdersReducer,


  userRecovery: userRecoveryReducer,


  addProductComment: addProductCommentReducer,


  // REPORTES

  getGroupPurchases: getGroupPurchasesReducer,
  getStudentsGroupPurchases: getStudentsGroupPurchasesReducer,

  getStudentWalletBalance: getStudentWalletBalanceReducer,
  getAssignmentsByArea: getAssignmentsByAreaReducer,


  /**RANDOM */
  getRandomConfiguration: getRandomConfigurationReducer,

  getGeneralSchoolInformation: getGeneralSchoolInformationReducer,


  /** SAVINGSCONF */
  getSavingsConf: getSavingsConfReducer,
  saveSavingsConf: saveSavingsConfReducer,

});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
