export const ENABLE_USERS_LIST_REQUEST = 'ENABLE_USERS_LIST_REQUEST';
export const ENABLE_USERS_LIST_SUCCESS = 'ENABLE_USERS_LIST_SUCCESS';
export const ENABLE_USERS_LIST_FAIL = 'ENABLE_USERS_LIST_FAIL';

export const ENABLE_USER_DETAL_REQUEST = 'ENABLE_USER_DETAL_REQUEST';
export const ENABLE_USER_DETAL_SUCCESS = 'ENABLE_USER_DETAL_SUCCESS';
export const ENABLE_USER_DETAL_FAIL = 'ENABLE_USER_DETAL_FAIL';


export const ENABLE_USERS_UPDATE_REQUEST = 'ENABLE_USERS_UPDATE_REQUEST';
export const ENABLE_USERS_UPDATE_SUCCESS = 'ENABLE_USERS_UPDATE_SUCCESS';
export const ENABLE_USERS_UPDATE_FAIL = 'ENABLE_USERS_UPDATE_FAIL';

